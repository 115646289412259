<template>
  <div>
    <template v-if="isDataEmpty">
      <van-cell
        title="数据"
        is-link
        arrow-direction="down"
        class="folded-title"
        @click="ifShowContent" />
      <van-empty v-if="active" image="search" description="暂无数据" />
    </template>
    <template v-else>
      <van-tabs v-if="active" class="speech-view" v-model="activeTab" :ellipsis="false" @click="clickTab = true">
        <template v-for="item in smartChartList">
          <van-tab :key="item.uuid">
            <div class="tab-title" slot="title" @touchmove="(event)=>{event.stopPropagation()}">
              <div v-if="item.thumb" class="tab-title-thumb" :style="{ backgroundImage: `url(${item.thumb})`}"></div>
              <ChartThumb v-else :chart-type="item['chartType']"/>
            </div>
            <div>
              <!-- <div class="divite"></div> -->
              <speech-smart-chart
                ref="speechSmartChart"
                v-if="item['chartType']"
                class="speech-smart-chart"
                :active="active"
                :uuid="item['uuid']"
                :chart-type="item['chartType']"
                :keyword="item['keyword']"
                :reportChartData="item['reportChartData']"
                :keywordMatch="item['keywordMatch']"
                :xAxis="item['adviseXAxis']"
                :keywordString="keywordMatched['keyword']"
                :keywordSearchHistoryId="keywordSearchHistoryId"
                @thumbCreated="onChartThumbCreated"
                :isHeadlines="isHeadlines"
                :isCollection="isCollection"
                :keywordMatched="keywordMatched"
                :reportData="reportData"
                :ignoreImplicit="ignoreImplicit"
                @FilterParseKeyword="FilterParseKeyword"
                @ifShowContent="ifShowContent" />
              <div style="height: 60px"></div>
            </div>
          </van-tab>
        </template>
      </van-tabs>
      <van-cell
        v-else
        title="数据"
        is-link
        arrow-direction="down"
        class="folded-title"
        @click="ifShowContent"/>
    </template>
  </div>
</template>
<script>
import domToImage from 'dom-to-image'
import SpeechSmartChart from './SpeechSmartChart'
import { uuid as uuidv4 } from '@/utils/utils'
import { mapState } from 'vuex'
import ChartThumb from '@/components/charts/ChartThumb'
export default {
  name: 'SpeechView',
  components: {
    ChartThumb,
    SpeechSmartChart
  },
  props: {
    keyword: {
      type: String,
      default: ''
    },
    defaultChartType: {
      type: String,
      default: ''
    },
    keywordSearchHistoryId: {
      type: String,
      default: ''
    },
    reportChartData: {
      type: Object,
      default() {
        return { }
      }
    },
    keywordMatched: {
      type: Object,
      default() {
        return { }
      }
    },
    reportDataRequest: {
      type: Object,
      default() {
        return { }
      }
    },
    reportData: {
      type: Object,
      default() {
        return { }
      }
    },
    ignoreImplicit: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTab: 0,
      keywordList: [],
      smartChartList: [],
      transformBaseData: {},
      activeTabKey: '',
      defaultXAxis: '',
      bodyHeightOuter: 200,
      bodyHeightInner: 200,
      chartRendered: null,
      isHeadlines: false,
      isCollection: false,
      clickTab: false // 标记是否通过点击事件改变active
    }
  },
  computed: {
    ...mapState({
      activeDataRegion: state => state.dataRegion.activeItem
    }),
    isDataEmpty() {
      const { dataItems } = this.reportChartData
      return !dataItems || dataItems.length === 0
    }
  },
  methods: {
    addSmartCharts () {
      this.clickTab = false
      const _result = []
      const _chartTypes = []
      if (this.reportDataRequest['bizObjectSearch']) {
        _chartTypes.push('Table')
      } else {
        _chartTypes.push(...this.reportChartData.getSmartChartTypes(this.keywordMatched['adviseDatetimeAxis']))
      }
      _chartTypes.forEach((type, index) => {
        const uuid = uuidv4()
        _result.push({
          uuid,
          chartType: type,
          adviseXAxis: this.keywordMatched['adviseDatetimeAxis'],
          keyword: this.keywordMatched['keywordArray'],
          reportChartData: this.reportChartData,
          keywordMatch: this.keywordMatched['keywordList'],
          thumb: null
        })
        if (index === 0) this.activeTabKey = uuid
      })

      this.smartChartList.splice(0)
      this.smartChartList.push(..._result)
      this.toDefaultTypeChart()
      this.getSearchTarckExists()
    },
    onChartThumbCreated (data) {
      // console.log('data', data)
      const _item = this.smartChartList.find(item => item['uuid'] === data['uuid'])
      if (_item) {
        if (!_item['rendered']) _item['rendered'] = true
        if (!/^data:,$/.test(data['thumb'])) {
          _item['thumb'] = data['thumb']
          _item['boxThumb'] = data['boxThumb']
          if (this.keywordSearchHistoryId) {
            this.$api.speech.updateHistoryThumb(this.keywordSearchHistoryId, { chartType: data['chartType'], thumbnail: data['thumb'] }).then(() => {
            // this.$emit('refreshHistory')
              console.log('this.$parent', this.$parent.$parent.$parent)
              if (this.$parent.$parent.$parent.$refs.recentTrackCard) {
                this.$parent.$parent.$parent.$refs.recentTrackCard.loadDataDelay()
              }
            })
          }
        }
      }
    },
    changeActiveTab (key) {
      let _index = this.smartChartList.findIndex(item => item['uuid'] === this.activeTabKey)
      if (key === 'NEXT') _index = (_index === (this.smartChartList.length - 1)) ? 0 : ++_index
      if (key === 'PREV') _index = _index === 0 ? 0 : --_index
      if (key === 'TABLE_DATA') {
        const __index = this.smartChartList.findIndex(item => item['chartType'] === 'Table')
        if (__index > -1) _index = __index
      }
      const _item = this.smartChartList[_index]
      this.activeTabKey = _item['uuid']
    },
    speechCommand (params) {
      const command = params['command']
      if (command === 'NEXT') this.changeActiveTab('NEXT')
      if (command === 'PREV') this.changeActiveTab('PREV')
      if (command === 'TABLE_DATA') this.changeActiveTab('TABLE_DATA')
    },
    toDefaultTypeChart () {
      this.$nextTick(() => {
        const chartType = this.defaultChartType || this.smartChartList[this.activeTab].chartType
        if (this.defaultChartType) {
          const _index = this.smartChartList.findIndex(item => item['chartType'] === this.defaultChartType)
          if (_index > -1) this.activeTab = _index
        }
        this.$emit('chartTypeChange', chartType)
      })
    },
    getSearchTarckExists () {
      const searchWords = this.keywordMatched.keyword
      const dataRegionId = this.activeDataRegion.id
      if (searchWords && dataRegionId) {
        this.$api.speech.searchTrackExists('collections', { // 是否收藏
          searchWords: searchWords,
          dataRegionId: dataRegionId
        }).then((collectionRes) => {
          this.isCollection = collectionRes.collected
        })
        this.$api.speech.searchTrackExists('headlines', { // 是否加入首页
          searchWords: searchWords,
          dataRegionId: dataRegionId
        }).then((headlinesRes) => {
          this.isHeadlines = headlinesRes.collected
        })
      }
    },
    FilterParseKeyword (params) {
      this.$emit('FilterParseKeyword', params)
    },
    ifShowContent () {
      this.$emit('ifShowContent')
    }
  },
  watch: {
    reportChartData (newVal, oldVal) {
      if (Object.keys(newVal).length !== 0) {
        this.addSmartCharts()
      }
    },
    activeTabKey (newVal) {
      const currentChart = this.smartChartList.find(item => item.uuid === newVal)
      if (currentChart) {
        this.$eventBus.$emit('currentChart', currentChart) // 传对象因为可能传的时候thumb还没有赋值好，直接传值可能获取不到
      }
    },
    activeTab (newVal, oldVal) {
      // console.log('newVal', newVal)
      // console.log('oldVal', oldVal)
      // if (!this.clickTab) { //  如果是点击tab，不重置为默认类型index
      //   this.toDefaultTypeChart()
      // }
      this.activeTabKey = this.smartChartList[newVal]['uuid']
      const thumbnail = this.smartChartList[newVal].thumb
      const rendered = this.smartChartList[newVal].rendered
      const chartType = this.smartChartList[newVal].chartType
      this.$emit('chartTypeChange', chartType)
      if (thumbnail && this.keywordSearchHistoryId) { // 切换到已缓存好缩略图的tab
        this.$api.speech.updateHistoryThumb(this.keywordSearchHistoryId, { chartType: chartType, thumbnail: thumbnail }).then(() => {
          this.$parent.$parent.$parent.$refs.recentTrackCard.loadDataDelay()
        }).catch((e) => {
          console.log(e)
        })
      } else if (rendered) { // 切换到已经点过，却没缓存好缩略图的tab
        this.$nextTick(() => {
          const chartItem = this.$refs['speechSmartChart'].find(item => item.uuid === this.activeTabKey)
          if (chartItem) {
            domToImage.toPng(chartItem.$refs['speechSmartChartBox'], { quality: 0.8 }).then(boxThumb => {
              domToImage.toPng(chartItem.$refs['refSpeechSmartChart'], { quality: 0.8 }).then(thumb => {
                this.onChartThumbCreated({
                  uuid: this.activeTabKey,
                  thumb,
                  chartType,
                  boxThumb: boxThumb
                })
              }).catch(function(error) {
                console.error('1、oops, something went wrong!', error)
              })
            }).catch(function(error) {
              console.error('2、oops, something went wrong!', error)
            })
          }
        })
      }
    }
  },
  mounted () {
    console.log('isDataEmpty', this.isDataEmpty)
    if (!this.isDataEmpty) {
      console.log('!this.isDataEmpty', this.isDataEmpty)
      this.$nextTick(() => {
        const _wrapperRect = this.$el.getBoundingClientRect()
        this.bodyHeightOuter = _wrapperRect.height
        this.bodyHeightInner = this.bodyHeightOuter - 10
      })
      this.addSmartCharts()
    }
    this.$eventBus.$on('SpeechCommand', this.speechCommand)
  },
  beforeUpdate() {
    const _wrapperRect = this.$el.getBoundingClientRect()
    if (this.bodyHeightOuter !== _wrapperRect.height) {
      this.bodyHeightOuter = _wrapperRect.height
      this.bodyHeightInner = this.bodyHeightOuter - 10
    }
  },
  destroyed() {
    this.$eventBus.$off('SpeechCommand', this.speechCommand)
  }
}
</script>
<style lang="less">
.speech-view {
  margin-bottom: 10px;
  background: #fff;
  position: relative;
  .folded-title {
    margin-bottom: -10px;
    padding: 10px 12px;
    font-size: 15px;
  }
  .van-tabs__wrap {
    border: 1px solid #E3E5E8;
    border-radius: 5px;
    height: 55px;
    padding: 0 0 5px;
    margin: 0 11px;
    background: #fff;
    position: absolute;
    bottom: 0;
    width: calc(100% - 24px);
    .van-tabs__nav {
      padding: 0 0 15px;
      .van-tab {
        padding: 5px 3px;
        width: 72px;
        // background: #F2F2F5;
        &:first-child {
          padding-left: 5px;
        }
        &:nth-last-child(2) {
          padding-right: 5px;
        }
        .van-tab__text {
          display: inline-block;
          width: 100%;
          height: 42px;
          background: #FFFFFF;
          border-radius: 2px;
          .tab-title {
            width: 100%;
            height: 100%;
            // border: 1px solid #eee;
            display: flex;
            justify-content: center;
            align-items: center;
            .tab-title-thumb {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
    }
    .van-tabs__line {
      bottom: 10px;
    }
  }
  .van-tabs__content {
    .speech-smart-chart-content {
      margin: 0 12px 10px;
      border: 1px solid #E3E5E8;
      border-radius: 5px;
    }
  }
}
</style>
