<template>
  <div>
    <van-cell :title="title"/>
    <div class="video-live-card">
      <video-player v-if="options['sources'].length > 0" :options="options" />
    </div>
  </div>
</template>
<script>
import VideoPlayer from './VideoPlayer'

export default {
  name: 'VideoLiveCard',
  components: {
    VideoPlayer
  },
  props: {
    title: {
      type: String,
      default () {
        return '视频播放'
      }
    },
    options: {
      type: Object,
      default() {
        return {
          liveui: true,
          fluid: true,
          autoplay: true,
          controls: true,
          sources: []
        }
      }
    }
  },
  data() {
    return {}
  },
  mounted() {
  }
}
</script>
<style lang="less" scoped>

</style>
