<template>
  <div class="recomment-word">
    <van-loading size="24px" v-if="loading">加载中...</van-loading>
    <div
      v-else
      v-for="item in dataList"
      :key="item.id"
      @click="onNodeClick(item)"
      :class="{ recomment: true, activeItem: item.active }">
      {{ item.nodeName }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'RecommentWord',
  props: {
    type: {
      type: String,
      default: ''
    },
    defaultActiveWords: {
      type: Array,
      default() {
        return []
      }
    },
    recommentWordParams: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      dataList: [],
      loading: true,
      activeWords: []
    }
  },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id
    })
  },
  methods: {
    getData () {
      console.log('this', this)
      this.loading = true
      this.$api.speech.getRecommends(this.recommentWordParams).then(res => {
        res.forEach(item => {
          item.active = false
        })
        this.dataList = res
        if (this.type === 'speechsearch') {
          const referenceObj = { id: 'reference', nodeName: '引用原搜索', active: true }
          this.dataList.splice(0, 0, referenceObj)
          this.activeWords.push(referenceObj)
          this.dataList.push({ id: 'more', nodeName: '更多...' })
          this.$emit('getActiveWords', this.activeWords)
        } else if (this.type === 'dialog') {
          this.resetDefaultWordsActive()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    onNodeClick (item) {
      if (item.id === 'more') {
        this.$emit('openMore')
      } else {
        const index = this.activeWords.findIndex(el => el.id === item.id)
        if (index === -1) {
          item.active = true
          this.activeWords.push(item)
        } else {
          item.active = false
          this.activeWords.splice(index, 1)
        }
        this.$emit('getActiveWords', this.activeWords)
      }
    },
    resetDefaultWordsActive () {
      this.activeWords.splice(0)
      this.dataList.forEach(data => {
        this.defaultActiveWords.forEach(words => {
          if (data.id === words.id) {
            data.active = true // 修改显示
            this.activeWords.push(data)
          } else {
            if (!this.activeWords.find(item => item.id === data.id)) { // 在activeWords内的不需要去除显示
              data.active = false
            }
          }
        })
      })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    recommentWordParams: {
      handler () {
        this.activeWords.splice(0)
        this.getData()
      },
      deep: true
    },
    defaultActiveWords: {
      handler () {
        this.resetDefaultWordsActive()
      },
      deep: true
    }
  }
}
</script>
<style lang="less" scoped>
.recomment-word {
  .recomment {
    display: inline-block;
    font-size: 14px;
    // border: 1px solid #c8c9ca;
    // border: 1px solid transparent;
    padding: 2px 4px;
    margin: 6px 9px;
    border-radius: 5px
  }
  .activeItem {
    background-color: #5b86e5;
    color: #ffffff;
    // font-weight: bold;
  }
}
</style>
