<template>
  <div class="search-recommend card">
    <div class="card-title">
      {{ title }}
      <span v-if="dataList.length" class="card-title-extra">
        {{ dataIndex + 1 }}/{{ dataList.length }}
      </span>
    </div>
    <div class="card-content">
      <van-swipe v-if="dataList.length" :show-indicators="false" @change="dataIndex = $event">
        <van-swipe-item v-for="(items, index) in dataList" :key="index">
          <van-cell
            title-class="van-ellipsis"
            v-for="item in items"
            :key="item['matchesHint']"
            clickable
            :label="item['label']"
            @click="onClickItem(item)">
            <template slot="title">
              <van-tag :color="item['tagColor']">{{ item['tag'] }}</van-tag>
              <span style="padding-left: 0.5em">{{ item['text'] }}</span>
            </template>
          </van-cell>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import _, { debounce } from 'lodash'
import { uuid } from '@/utils/utils'
import { mapState } from 'vuex'

const keywordCategories = {
  DIMENSION_VALUE: { color: '#1890FF' },
  DIMENSION_TIME: { color: '#73C9E6' },
  DIMENSION_NAME: { color: '#13C2C2' },
  METRICS_NAME: { color: '#6CD9B3' },
  DATETIME: { color: '#2FC25B' },
  BIZ_OBJECT: { color: '#9DD96C' }
}
// const colors = ['#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#2FC25B', '#9DD96C', '#FACC14', '#E6965C']

export default {
  name: 'SearchRecommend',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '推荐术语'
    }
  },
  data () {
    return {
      loading: false,
      hintsRequestId: '',
      lastMatch: '',
      dataList: [],
      res: {},

      dataIndex: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 30,
      totalElements: 0
    }
  },
  computed: {
    ...mapState({
      dataRegion: state => state.dataRegion.activeItem,
      keyword: state => state.speech.inputKeyword
    })
  },
  methods: {
    getKeywordHistoryList () {
      this.loading = true
      this.hintsRequestId = uuid()
      this.$api.speech.keywordHints({
        keyword: this.keyword,
        requestId: this.hintsRequestId,
        dataRegionId: this.dataRegion.id
        // wholeMatch: false
      }).then(res => {
        this.loading = false
        this.res = res
        const { keywordList, requestId } = res
        if (requestId === this.hintsRequestId) {
          // this.transformTagItemsDelay()
          this.lastMatch = keywordList.pop()
          if (this.lastMatch) {
            this.dataList = this.processData(this.lastMatch.result)
          } else {
            this.dataList.splice(0)
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    processData (data) {
      data.forEach(item => {
        const { category, keywordInput, matchesHint, keywordOutputDirect, relationKeyword, categoryDisplayName } = item
        item.tagColor = '#888888'
        if (keywordCategories[category]) {
          item.tagColor = keywordCategories[category]['color']
        }
        item.tag = relationKeyword || categoryDisplayName
        if (category === 'METRICS_NAME' && matchesHint !== keywordOutputDirect) {
          item.label = keywordOutputDirect
        }
        item.text = ['SHOW_FORM', 'DATETIME'].includes(category) ? matchesHint : keywordInput
      })
      return _.chunk(data, 5)
    },
    onClickItem (item) {
      const { category, matchesHint, keywordInput } = item
      const replace = this.lastMatch.keyword
      const target = ['SHOW_FORM', 'DATETIME'].includes(category) ? matchesHint : keywordInput
      this.$emit('completion', { replace, target })
    }
  },
  mounted() {
    this.getKeywordHistoryListDelay = debounce(this.getKeywordHistoryList, 300)
    this.getKeywordHistoryList()
  },
  watch: {
    keyword (newVal, oldVal) {
      this.getKeywordHistoryList()
    }
  }
}
</script>
<style lang="less" scoped>
.search-recommend {
  .van-cell-group__title {
    padding: 8px 16px 6px;
  }
}
</style>
