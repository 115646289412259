<template>
  <div class="search-speech">
    <div v-if="this.smartType === 'RICH_REPORT'">
      <RichReportComponent
        :richModel="richModel"
        :active="active"
        :keywordSearchHistoryId="keywordSearchHistoryId"
        @rendered="saveHistory"
        @ifShowContent="ifShowContent"/>
    </div>
    <div v-else>
      <SpeechView
        class="speech-view"
        ref="speechView"
        :active="active"
        :keywordMatched="keywordMatched"
        :reportDataRequest="keywordParse.reportDataRequest"
        :reportChartData="reportChartData"
        :defaultChartType="chartType"
        :keywordSearchHistoryId="keywordSearchHistoryId"
        :reportData="origin.reportData"
        :ignoreImplicit="ignoreImplicit"
        @FilterParseKeyword="parseKeyword"
        @chartTypeChange="chartTypeChange"
        @ifShowContent="ifShowContent" />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import KeywordMatched from '@/utils/keywordMatched'
import ReportChartData from '@/utils/reportData'
import SpeechView from '@/components/speech/SpeechView'
import RichReportComponent from '@/views/richReport/view/RichReportComponent'

export default {
  name: 'SearchSpeech',
  components: {
    SpeechView,
    RichReportComponent
  },
  props: {
    // smartType: {
    //   type: String,
    //   default: ''
    // },
    active: {
      type: Boolean,
      default: false
    },
    searchId: {
      type: String,
      default: ''
    },
    chartId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      SEARCH_STATUS: {
        WAITING: 0,
        INPUTTING: 1,
        PARSING: 2,
        REPORTING: 3,
        RENDERING: 4
      },
      status: '',
      smartType: '',
      chartType: '',
      origin: {
        keywordParse: {
          keywordMatch: [],
          reportDataRequest: {}
        },
        reportData: {}
      },
      keywordSearchHistoryId: null,
      // 本次交互关键词输入【回车】列表
      keywordSession: [],
      richModel: {},
      richReportNotSaveHistory: false,
      keywordMatched: null,
      ignoreImplicit: true,
      reportChartData: {},
      keywordParse: {
        keywordMatch: [],
        reportDataRequest: {}
      }
    }
  },
  computed: {
    ...mapState({
      activeDataRegionId: state => state.dataRegion.activeItem.id,
      searchHistories: state => state.speech.searchHistories,
      historyLocations: state => state.speech.historyLocations
    }),
    keyword: {
      get () {
        return this.$store.state.speech.inputKeyword
      },
      set (val) {
        this.$store.commit('speech/setInputKeyword', val)
      }
    }
  },
  methods: {
    searchItem (history, hasHistory) { // hasHistory 从别处切换回来有历史数据，不显示主页
      if (hasHistory) this.$emit('showPage', null, false)
      const dataRequest = _.cloneDeep(history.dataRequest)
      const diyModel = _.cloneDeep(history.diyModel)
      const { searchWords, chartType } = history
      this.chartType = chartType
      this.keyword = searchWords
      this.$emit('changeSearchWord', this.keyword)
      if (dataRequest) {
        // if (dataRequest && keywordSearchHistoryId) {
        const { origin } = this

        this.keywordSearchHistoryId = dataRequest.keywordSearchHistoryId
        origin.keywordParse = {
          keywordMatch: dataRequest['matchKeywords'],
          reportDataRequest: dataRequest
        }
        this.keywordSession.splice(0)
        this.keywordSession.push(...searchWords.split('|'))
        this.getReportData({ refreshHistory: true, notSaveHistory: history.notSaveHistory || hasHistory }) // refreshHistory = true, 请求加入历史记录， notSaveHistory：已经存在本地历史，是点左右箭头进行历史查看，不需要加入本地历史列表
      } else if (diyModel) {
        const { origin } = this
        this.keywordSearchHistoryId = history.keywordSearchHistoryId
        origin.keywordParse = {
          keywordMatch: history.keywordMatch,
          diyModel: diyModel
        }
        this.keywordSession.splice(0)
        this.keywordSession.push(...searchWords.split('|'))
        this.$emit('result', origin.keywordParse['keywordMatch'])
        this.getDiyModelData(diyModel, { notSaveHistory: history.notSaveHistory || hasHistory }) // refreshHistory = true, 加入历史记录
      } else {
        this.parseKeyword(null, true) // 第二个参数，左右箭头读取历史时，当keywordMatch=[]，不保存历史
      }
    },
    parseKeyword (filterKeyword, notSaveNullDataHistory) { // filterKeyword：KeywordFilterIcon中对搜索内容修改后的关键词, notSaveNullDataHistory: 左右箭头读取历史时，当keywordMatch=[]，不保存历史
      const keyword = filterKeyword || this.keyword
      const { origin, keywordSearchHistoryId, keywordSession } = this
      this.status = this.SEARCH_STATUS.PARSING
      this.smartType = 'COMMON_SEARCH'
      this.chartType = ''
      // const { keywordMatch } = keywordParse
      this.$toast.loading({
        message: '语义解析…'
      })
      this.$api.speech.parseMixKeyword({
        keywords: [keyword],
        dataRegionId: this.activeDataRegionId,
        printDataRequest: true,
        ignoreHistory: false,
        previousKeywordSearchHistoryId: keywordSearchHistoryId
        // 不要 previousKeywordMatch 了，后面根据 keywordSearchHistoryId 自选处理
        // previousKeywordMatch: keywordMatch
      }).then(res => {
        const { keywordMatch, reportDataRequest, implicit } = res
        const richReportViewKeyword = keywordMatch.find(k => k['keywordCategory'] === 'REPORT_VIEW' && k['relationEntityId'])
        if (richReportViewKeyword) {
          // step 1 判断是否有分析报告关键词
          const richReportId = richReportViewKeyword['relationEntityId']
          const { keyword } = this
          this.smartType = 'RICH_REPORT'
          this.richModel = {
            id: richReportId,
            keyword: keyword,
            paramList: _.cloneDeep(keywordMatch)
          }
          this.status = this.SEARCH_STATUS.RENDERING
          this.richReportNotSaveHistory = false
          this.keywordMatched = new KeywordMatched(this.keyword, keywordMatch)
          this.$emit('showPage', true, true) // 第一个参数为hasSearchResult，第二个参数为showContent
          this.$emit('result', keywordMatch)
          console.log(origin)
        } else if (keywordMatch.length > 0) {
          // step 2 普通搜索，关键词列表不能为空
          this.origin.keywordParse = res
          if (res['diyModel']) {
            keywordSession.splice(0)
            this.getDiyModelData(res['diyModel'])
          } else {
            this.keywordSearchHistoryId = reportDataRequest['keywordSearchHistoryId']
            if (!reportDataRequest['secondInteract']) keywordSession.splice(0)
            if (implicit) {
              this.$dialog.confirm({
                title: '检测到多个 ' + res.implicitCategories.join('、'),
                confirmButtonText: '展开查看',
                cancelButtonText: '合计查看',
                cancelButtonColor: '#1989fa'
              }).then(() => {
                this.ignoreImplicit = false
                this.getReportData()
              }).catch(() => {
                this.ignoreImplicit = true
                this.getReportData({ ignoreImplicit: true })
              })
            } else {
              this.getReportData()
            }
          }
          keywordSession.push(keyword)
        } else {
          // step 3 无搜索结果，保持现状
          this.status = this.SEARCH_STATUS.WAITING
          this.reportChartData = Object.assign({})
          // 本地历史，没有图表时，其他标签也要显示历史
          const notSaveHistory = notSaveNullDataHistory || false
          this.saveHistory(notSaveHistory, {
            searchWords: this.keyword
          })
          this.$emit('showPage', null, true) // 第一个参数为hasSearchResult，第二个参数为showContent
          this.$emit('result', [])
          // this.$toast.fail('未能匹配')
        }
      }).catch((err) => {
        console.log(err)
        this.status = this.SEARCH_STATUS.WAITING
        this.$toast.fail('未能解析')
        this.$emit('result', [])
      })
    },
    getReportData (params) {
      this.smartType = 'COMMON_SEARCH'
      this.$toast.loading({
        message: '加载数据…',
        forbidClick: true
      })
      const param = params || {}
      const { origin, keywordSession } = this
      this.status = this.SEARCH_STATUS.REPORTING
      this.$api.speech.getReportData(origin.keywordParse.reportDataRequest, param.ignoreImplicit, param.refreshHistory).then(res => {
        // 本地历史
        this.saveHistory(param.notSaveHistory, {
          searchWords: keywordSession.join('|'),
          chartType: this.chartType,
          keywordSearchHistoryId: this.keywordSearchHistoryId,
          dataRequest: _.cloneDeep(origin.keywordParse.reportDataRequest)
        })
        this.keywordMatched = new KeywordMatched(keywordSession.join('|'), origin.keywordParse.keywordMatch)
        this.keywordParse = _.cloneDeep(origin.keywordParse)
        this.keyword = ''

        origin.reportData = res[0]
        this.reportChartData = new ReportChartData(res[0])
        this.status = this.SEARCH_STATUS.RENDERING
        this.$emit('showPage', true, true)
        this.$emit('result', this.reportChartData?.dataItems)
        this.$toast.clear()
      }).catch((err) => {
        console.log(err)
        this.$toast.fail('')
        this.status = this.SEARCH_STATUS.WAITING
        this.$emit('showPage', null, true)
        this.$emit('result', [])
      })
    },
    getDiyModelData(diyModelRequest, params) {
      this.smartType = 'DIY_MODEL'
      this.status = this.SEARCH_STATUS.REPORTING
      const { origin, keywordSession } = this
      const param = params || {}
      this.$api.speech.getDiyModelData(diyModelRequest).then(res => {
        // this.reportData = res// 本地历史
        this.saveHistory(param.notSaveHistory, {
          searchWords: keywordSession.join('|'),
          chartType: this.chartType,
          keywordSearchHistoryId: this.keywordSearchHistoryId,
          diyModel: _.cloneDeep(origin.keywordParse.diyModel),
          keywordMatch: _.cloneDeep(origin.keywordParse.keywordMatch)
        })
        this.keywordMatched = new KeywordMatched(keywordSession.join('|'), origin.keywordParse.keywordMatch)
        this.keywordParse = _.cloneDeep(origin.keywordParse)
        this.keyword = ''
        origin.reportData = res
        this.reportChartData = new ReportChartData(res)
        this.status = this.SEARCH_STATUS.RENDERING
        this.$emit('showPage', true, true) // 第一个参数为hasSearchResult，第二个参数为showContent
        this.$emit('result', this.reportChartData?.dataItems)
        this.$toast.clear()
      }).catch(() => {
        this.$toast.fail('请求数据错误')
        this.$emit('result', [])
        this.$emit('showPage', null, true)
        this.status = this.SEARCH_STATUS.WAITING
      })
    },
    saveHistory (notSaveHistory, params) { // 普通图表时参数，notSaveHistory表示不保存历史，params表示保存的参数
      const refSearchInput = this.$parent.$parent.$refs.refSearchInput
      console.log('saveHistory', refSearchInput)
      if (refSearchInput.saveHistory) {
        if (this.smartType === 'RICH_REPORT' && !this.richReportNotSaveHistory) {
          refSearchInput.saveHistory({
            searchWords: this.richModel.keyword,
            richReportModel: this.richModel
          })
        } else if (!notSaveHistory) {
          refSearchInput.saveHistory(params)
        }
        refSearchInput.searchSuccess()
      }
    },
    ifShowContent () {
      this.$emit('ifShowContent')
    },
    chartTypeChange (chartType) {
      this.chartType = chartType
      this.$store.dispatch('speech/setSearchHistoryChartType', { dataRegionId: this.activeDataRegionId, chartType })
    },
    reset () {
      this.keywordParse = {
        keywordMatch: [],
        reportDataRequest: {}
      }
      this.reportChartData = {}
      this.origin = {
        keywordParse: {
          keywordMatch: [],
          reportDataRequest: {}
        },
        reportData: {}
      }
      this.keywordSearchHistoryId = null
      this.$emit('showPage', false, null)
    },
    predictData (options) {
      const _dataRequest = JSON.parse(JSON.stringify(this.keywordParse.reportDataRequest))
      _dataRequest.predict = {}
      _dataRequest.predict.count = options.count
      _dataRequest.predict.fieldType = options.fieldType
      this.$api.speech.getReportData(_dataRequest).then(res => {
        // this.reportDataList = res
        this.reportChartData = new ReportChartData(res[0])
      }).catch(error => {
        this.$toast.fail(error.response.data.message)
      })
    },
    /**
     * 获取对标数据分类
     */
    getBenchmarkingCategory () {
      this.$api.benchmarking.getBenchmarkingCategory().then(res => {
        this.$store.commit('speech/setBenchmarkingCategory', res.data)
      }).catch((e) => {})
      this.$api.benchmarking.getBenchmarkingDataset().then(res => {
        this.$store.commit('speech/setBenchmarkingDataset', res.dataTables[0])
      }).catch((e) => {})
    },
    showChartDetail (historyData, dataRes) { // 从别的页面跳转回来，查看图表详情
      let dataRequest = dataRes
      if (historyData) {
        dataRequest = historyData.dataRequest // 有historyData时不会传dataRequest
        this.chartType = historyData.chartType
        this.keyword = historyData.searchWords
        this.keywordSearchHistoryId = historyData.keywordSearchHistoryId
        if (this.keyword) {
          this.keywordSession.splice(0)
          this.keywordSession.push(...this.keyword.split('|'))
        }
      }
      if (dataRequest) {
        this.$emit('showPage', null, false)
        this.$store.commit('dataRegion/setActiveId', dataRequest.dataRegionId)
        this.origin.keywordParse = {
          keywordMatch: dataRequest['matchKeywords'],
          reportDataRequest: dataRequest
        }
        this.getReportData()
      }
    },
    clickLeftArrow () {
      const dataRegionId = this.activeDataRegionId
      const historyDataList = this.searchHistories[dataRegionId]
      let historyLocation = this.historyLocations[dataRegionId]
      if (historyLocation !== 0) { // 为0时，左边没有数据， 不变化
        historyLocation--
        const data = historyDataList[historyLocation]
        this.changeHistoryLocation(historyLocation)
        if (data.richReportModel) {
          this.smartType = 'RICH_REPORT'
          this.richModel = _.cloneDeep(data.richReportModel)
          this.richReportNotSaveHistory = true
          this.$emit('showPage', true, true)
          this.keyword = this.richModel.keyword
        } else {
          this.searchItem({
            searchWords: data.searchWords,
            chartType: data.chartType,
            keywordSearchHistoryId: data.keywordSearchHistoryId,
            keywordMatch: data.keywordMatch,
            dataRequest: data.dataRequest,
            diyModel: data.diyModel,
            notSaveHistory: true
          }) // 没有本地历史记录，不保存历史数据列表
        }
      }
    },
    clickRightArrow () {
      const dataRegionId = this.activeDataRegionId
      const historyDataList = this.searchHistories[dataRegionId]
      let historyLocation = this.historyLocations[dataRegionId]
      if (historyDataList.length - 1 > historyLocation) { // historyLocation为最后一个时，右边没有数据
        historyLocation++
        const data = historyDataList[historyLocation]
        this.changeHistoryLocation(historyLocation)
        if (data.richReportModel) {
          this.smartType = 'RICH_REPORT'
          this.richModel = _.cloneDeep(data.richReportModel)
          this.richReportNotSaveHistory = true
          this.$emit('showPage', true, true)
          this.keyword = this.richModel.keyword
        } else {
          this.searchItem({
            searchWords: data.searchWords,
            chartType: data.chartType,
            keywordSearchHistoryId: data.keywordSearchHistoryId,
            keywordMatch: data.keywordMatch,
            dataRequest: data.dataRequest,
            diyModel: data.diyModel,
            notSaveHistory: true
          }) // 没有本地历史记录，不保存历史数据列表
        }
      }
    },
    changeHistoryLocation (historyLocation) {
      this.$store.commit('speech/setHistoryLocation', { dataRegionId: this.activeDataRegionId, historyLocation })
    }
  },
  mounted () {
    this.$eventBus.$on('predictData', this.predictData)
    // this.getBenchmarkingCategory()
  },
  watch: {
    keywordMatched (val) {
      this.$emit('getChartData', { keywordMatched: val })
    },
    keywordSearchHistoryId (val) {
      this.$emit('getChartData', { keywordSearchHistoryId: val, chartId: this.chartId })
    },
    'origin.reportData' (val) {
      this.$emit('getChartData', { originReportData: val })
    },
    ignoreImplicit (val) {
      this.$emit('getChartData', { ignoreImplicit: val })
    },
    searchId: {
      handler (val) {
        if (val !== this.keywordSearchHistoryId) {
          this.keywordSearchHistoryId = val
        }
      },
      immediate: true
    }
  },
  destroyed() {
    this.$eventBus.$off('predictData')
  }
}
</script>
<style lang="less">
</style>
