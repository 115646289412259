<template>
  <div class="search-document" v-if="active">
    <div class="search-result" @touchmove.stop>
      <template v-if="this.dataList.length === 0">
        <van-empty image="search" description="暂无数据" />
      </template>
      <van-list
        :loading="loading"
        :finished="finished"
        :error="error"
        error-text="请求失败，点击重新加载"
      >
        <van-cell v-for="item in dataList" :key="item['uuid']">
          <template #title>
            <a href="javaScript:void(0)" @click.prevent="clickItem(item)">{{ item['title'] }}</a>
          </template>
          <template #label>
            <div class="documnet-content" v-html="item['highlight']['content'] || item['source']['content']"></div>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SearchDocument',
  components: {},
  props: {
    indexName: {
      type: String,
      default: 'webinfo'
    },
    keyword: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keywordInput: '',
      loading: false,
      finished: false,
      error: false,
      dataList: [],
      fields: ['title', 'content'],
      pagination: {
        current: 1,
        totalPage: 0,
        pageSize: 10
      }
    }
  },
  methods: {
    queryHitPage (reload) {
      if (reload) {
        this.loading = true
        this.finished = false
        this.error = false
        this.dataList.splice(0)
        this.pagination.current = 1
      }
      this.$api.searchDocument.queryHitPage({
        keyword: this.keyword,
        indexName: this.indexName,
        fields: this.fields.join(','),
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current
      }).then(res => {
        console.log(res['list'])
        this.dataList.push(...res['list'].map(item => {
          item['uuid'] = uuidv4()
          if (item['source']['file']) {
            item['url'] = item['source']['url']
            item['title'] = item['source']['file']['filename']
          } else {
            item['url'] = item['source']['url']
            item['title'] = item['highlight']['title'] || item['source']['title']
          }
          return item
        }))
        console.log(this.dataList.length)
        this.pagination.totalPage = res['totalPage']
        this.pagination.current = res['pageNo']
        this.loading = false
        // if (this.pagination.totalPage === this.pagination.current) {
        this.finished = true // 只显示十条
        this.$emit('result', this.dataList)
        // } else {
        //   this.pagination.current++
        //   this.queryHitPage()
        // }
      }).catch(() => {
        this.dataList.splice(0)
        this.error = true
        this.$emit('result', this.dataList)
      })
    },
    clickItem (item) {
      let title = ''
      if (this.indexName === 'webinfo') {
        title = '网页'
      } else {
        title = '文档'
      }
      this.$parent.$parent.$refs['refDocumentPreview'].show(item['url'], title)
    }
  },
  watch: {
    keyword () {
      this.queryHitPage(true)
    }
  }
}
</script>
<style lang="less">
  .search-document {
    .search-result {
      max-height: 300px;
      overflow: auto;
      .documnet-content {
        display: -webkit-box; /* 该方法适用于WebKit浏览器及移动端 */
        -webkit-box-orient: vertical;
        // text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
      }
    }
  }
</style>
