<template>
  <div class="relationship-diagram">
    <van-cell class="card-title" title="">
      <div slot="right-icon" class="extra">
        <img src="@/assets/img/icon/menu.png" class="more-img" @click="showKeywordSelectDialog"/>
        <img src="@/assets/img/icon/reload.png" class="more-img" @click="nextGroupData"/>
      </div>
    </van-cell>
    <div id="mountNode"></div>
  </div>
</template>
<script>
import G6 from '@antv/g6'
import { mapState } from 'vuex'
import { getStrFullLength } from '@/utils/utils'

export default {
  name: 'RelationshipDiagram',
  components: {},
  props: {
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id
    })
  },
  data () {
    return {
      data: {
        nodes: [],
        edges: []
      },
      colorList: {
        nodeFills: { '指标': '#FFB643' },
        nodeStrokes: { '时间条件': '#FFE434', '时间维度': '#FFE434', '指标': '#FFB643', '业务维度': '#17FFFF', '业务实体': '#D9D9D9' }
      },
      graph: null,
      width: 300,
      clickText: '',
      clickNum: 0,
      clickTime: {
        timeA: '',
        timeB: ''
      },
      start: 1,
      end: 4
    }
  },
  methods: {
    getNodeList () {
      const _data = {
        'returnLimit': 10,
        'start': this.start,
        'type': '指标',
        'end': this.end,
        'dataRegionId': this.dataRegionId
      }

      this.$api.speech.getRelationshipDiagram(_data).then(res => {
        this.data = res
        this.formatNodeData()
        this.graph.changeData(this.data)
      }).catch(error => {
        this.$message.error(error.response.data.message)
      })
    },
    showKeywordSelectDialog () {
      this.$parent.$parent.$refs['refKeywordSelectDialog'].showDialog()
    },
    formatNodeData () {
      this.data.nodes.forEach(item => {
        item.id += ''
        if (getStrFullLength(item.nodeName) > 8) {
          const len = item.nodeName.length
          let i = 0
          for (; i < len; i++) {
            const str = item.nodeName.substring(0, i)
            if (getStrFullLength(str) > 6) {
              item.label = item.nodeName.substring(0, i - 1) + '...' // 用于图表显示
              break
            }
          }
          if (i === len) item.label = item.nodeName
        } else {
          item.label = item.nodeName // 用于图表显示
        }
        const nodeStyle = {}
        const labelStyle = { fill: '#FFFFFF' }
        if (item['wordType'] === '指标') {
          labelStyle['stroke'] = '#D57C1C'
          labelStyle['lineWidth'] = 2
        }
        nodeStyle['fill'] = this.colorList.nodeFills[item['wordType']] || 'transparent'
        nodeStyle['stroke'] = this.colorList.nodeStrokes[item['wordType']] || '#5d9beb'
        item['style'] = nodeStyle
        item['labelCfg'] = { style: labelStyle }
      })
      this.data.edges.forEach(item => {
        item.source += ''
        item.target += ''
      })
    },
    getClickContent (e) {
      const model = e.item.get('model')
      this.clickText = model.nodeName
      if (this.clickNum === 0) { // 第一次点击
        this.clickTime.timeA = new Date()
        this.clickNum++
        setTimeout(() => {
          if (this.clickNum === 1) {
            // console.log('clickOne')
            this.clickNum = 0
            this.$emit('clickDiagramLabel', this.clickText)
          }
        }, 350)
      } else if (this.clickNum === 1) { // 第二次点击
        this.clickTime.timeB = new Date()
        if (Math.abs(this.clickTime.timeA - this.clickTime.timeB) < 300) {
          // console.log('clickTwo')
          //  双击 function
          if (model.wordType === '业务实体') {
            this.searchKindDesc()
          }
        } else {
          this.clickTime.timeA = new Date()
          // console.log('clickOne2')
          this.$emit('clickDiagramLabel', this.clickText)
        }
        this.clickNum = 0
      }
      console.log('this.clickText', this.clickText)
    },
    searchKindDesc () {
      const _data = {
        page: 0,
        size: 10,
        keywordCategory: '业务实体',
        sortDirection: 'DESC',
        synonym: false,
        dataRegionId: this.dataRegionId,
        searchKeyword: this.clickText
      }
      this.$api.keyword.getKeywordList(_data).then(res => {
        if (res['content'].length > 0) {
          this.clickText = '各' + res['content'][0]['kindDesc']
          this.$emit('clickDiagramLabel', this.clickText)
        }
      }).catch(error => {
        this.$message.error(error.response.data.message)
      })
    },
    // refreshDragedNodePosition(e) {
    //   console.log('this.')
    //   const model = e.item.get('model')
    //   model.fx = e.x
    //   model.fy = e.y
    // },
    initGraph () {
      this.width = this.$el.offsetWidth - 1
      const _this = this
      this.graph = new G6.Graph({
        container: 'mountNode', // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
        width: _this.width, // Number，必须，图的宽度
        height: _this.height, // Number，必须，图的高度
        // fitCenter: true,
        defaultNode: {
          size: 50
        },
        defaultEdge: {
          type: 'line',
          style: {
            stroke: '#01ADFF'
          }
        },
        layout: {
        // type: 'fruchterman',
        // gravity: 5, // 可选
        // speed: 2, // 可选
        // maxIteration: 200, // 可选，迭代次数
        // workerEnabled: true, // 可选，开启 web-worker
        // gpuEnabled: true // 可选，开启 GPU 并行计算，G6 4.0 支持

          // type: 'random',
          type: 'force',
          preventOverlap: true, // 防止结点重叠
          nodeSpacing: 15,
          // linkDistance: (d) => {
          //   if (_this.height > 249) {
          //     return 55
          //   } else if (_this.height > 200) {
          //     return 30
          //   } else {
          //     return 20
          //   }
          // },
          nodeStrength: (d) => {
            if (_this.height > d.y) {
              return 50
            } else {
              return 20
            }
            // if (_this.height > 249) {
            //   return -80
            // } else if (_this.height > 200) {
            //   return -50
            // } else {
            //   return -20
            // }
          }
          // edgeStrength: (d) => {
          //   if (_this.height > 249) {
          //     return 0.1
          //   } else if (_this.height > 200) {
          //     return 0.125
          //   } else {
          //     return 0.15
          //   }
          // }
        },
        modes: {
          default: ['zoom-canvas', 'drag-canvas']
        }
      })

      _this.graph.data(_this.data) // 读取数据源到图上
      _this.graph.render() // 渲染图

      _this.graph.on('node:touchstart', function (e) {
        _this.getClickContent(e)
      })
      // _this.graph.on('node:touchstart', function (e) {
      //   _this.graph.layout()
      //   _this.refreshDragedNodePosition(e)
      // })
      // _this.graph.on('node:touchmove', function (e) {
      //   const forceLayout = _this.graph.get('layoutController').layoutMethods[0]
      //   forceLayout.execute()
      //   _this.refreshDragedNodePosition(e)
      // })
      // _this.graph.on('node:touchend', function (e) {
      //   e.item.get('model').fx = null
      //   e.item.get('model').fy = null
      // })

      if (typeof window !== 'undefined') {
        window.onresize = () => {
          if (!_this.graph || _this.graph.get('destroyed')) return
          if (!_this.$el || !_this.$el.offsetWidth) return
          _this.width = _this.$el.offsetWidth - 1
          _this.graph.changeSize(_this.width, _this.height)
        }
      }
    },
    nextGroupData () {
      this.start = this.end + 1
      this.end += 4
      this.getNodeList()
    }
  },
  mounted() {
    this.initGraph()
    this.getNodeList()
    // this.formatNodeData()

    // graph.on('node:touchstart', function (e) {
    //   graph.layout()
    //   _this.refreshDragedNodePosition(e)
    // })
    // graph.on('node:touchmove', function (e) {
    //   const forceLayout = graph.get('layoutController').layoutMethods[0]
    //   forceLayout.execute()
    //   _this.refreshDragedNodePosition(e)
    // })
    // graph.on('node:touchend', function (e) {
    //   e.item.get('model').fx = null
    //   e.item.get('model').fy = null
    // })
  },
  watch: {
    height (val) {
      this.graph.changeSize(this.width, val)
    }
  }
}
</script>
<style lang="less">
.relationship-diagram {
  margin: 0 15px;
  .card-title {
    background-color: transparent;
    padding: 0 16px;
    border: none;
    position: absolute;
    right: 0;
    .extra {
      .more-img {
        margin-left: 10px;
        width: 17px;
        height: 17px;
      }
    }
  }
  .van-cell::after {
    border: none;
  }
}
</style>
