<template>
  <div class="search-input">
    <van-search
      ref="refSearchInput"
      v-model="keyword"
      :show-action="true"
      :placeholder="placeholder"
      :shape="shape"
      :readonly="readonly"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
      @search="onSearch"
      @cancel="onCancel"
    >
      <template #action>
        <div v-if="showCancel" @click="onCancel">取消</div>
        <div v-else @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div v-if="showRecommended" class="scroller-wrapper" :style="{height: `calc(100vh - 100px - ${moreHeight}px)`}">
      <scroller class="keyword-suggest">
        <div @touchstart="closeKeyboard">
          <SearchRecommend @completion="onCompletion"/>

          <!-- <SearchTrackCard
            title="最近搜索"
            type="recent"
            layout="list"
            :cols="1"
            :rows="6"
            :keyword="keyword"
            @searchItem="searchItem" /> -->

          <SearchTrackCard
            title="您可能搜索"
            type="suggests"
            layout="list"
            :cols="1"
            :rows="6"
            :keyword="keyword"
            @searchItem="searchItem" />
        </div>
      </scroller>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import SearchRecommend from '@/components/speech/SearchRecommend'
import SearchTrackCard from '@/components/speech/SearchTrackCard'

const DEFAULT_PLACEHOLDER = '请输入搜索关键词'

export default {
  name: 'SearchInput',
  components: {
    SearchRecommend,
    SearchTrackCard
  },
  props: {
    defaultKeyword: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      keyword: '',
      shape: 'square',
      readonly: false,
      placeholder: DEFAULT_PLACEHOLDER,
      showCancel: false,
      loading: false,
      focus: false
    }
  },
  computed: {
    ...mapState({
      dataRegion: state => state.dataRegion.activeItem,
      inputStatus: state => state.speech.inputStatus,
      searchHistories: state => state.speech.searchHistories,
      historyLocations: state => state.speech.historyLocations,
      INPUT_STATUS_OPTION: state => state.speech.INPUT_STATUS_OPTION,
      moreHeight: state => state.global.moreHeight
    }),
    showRecommended () {
      return this.inputStatus === this.INPUT_STATUS_OPTION.TYPING
    },
    speechRecognizedKeyword() {
      return this.$store.state.speech.speechRecognizedKeyword
    }
  },
  methods: {
    setKeyword(keyword) {
      console.log('setKeyword', keyword)
      this.$store.commit('speech/setInputKeyword', keyword)
      this.keyword = keyword
    },
    onSearch () {
      this.$emit('search', this.keyword)
      this.idle()
    },
    onCompletion(param) {
      console.log('onCompletion')
      if (this.keyword === param['replace']) {
        this.keyword = param['target']
        this.$store.commit('speech/setInputKeyword', this.keyword)
      } else {
        const arr = this.keyword.split(param['replace'])
        if (arr.length > 1) {
          const arr2 = []
          arr2.push(arr.pop())
          arr2.push(param['target'])
          arr2.push(arr.pop())
          arr2.reverse()
          arr.push(arr2.join(''))
          const keyword = arr.join(param['replace'])
          this.setKeyword(keyword)
        }
      }
      this.inputFocusDelay()
    },
    searchItem(item) {
      console.log('searchItem')
      const { searchWords } = item
      this.setKeyword(searchWords)
      this.inputFocusDelay()
      // this.onSearch()
    },
    onFocus () {
      this.placeholder = DEFAULT_PLACEHOLDER
      this.focus = true
      this.showCancel = true
      this.$store.dispatch('global/setHideTabBar', true)
      this.$store.commit('speech/setInputStatus', this.INPUT_STATUS_OPTION.TYPING)
      this.shape = 'round'
    },
    onBlur () {
      this.blurDelay()
    },
    blur () {
      this.focus = false
    },
    onInput (val) {
      this.$emit('change', val)
      this.$store.dispatch('speech/setInputKeyword', val)
    },
    onCancel () {
      this.idle()
    },
    idle () {
      this.focus = false
      this.showCancel = false
      this.$store.dispatch('global/setHideTabBar', false)
      this.$store.commit('speech/setInputStatus', this.INPUT_STATUS_OPTION.IDLE)
      this.shape = 'square'
      this.$emit('cancelInput')
    },
    reset () {
      console.log('reset')
      const historyList = this.searchHistories[this.dataRegion.id]
      const location = this.historyLocations[this.dataRegion.id]
      if (location !== -1 && historyList && !this.speechRecognizedKeyword) {
        const history = historyList[location]
        console.log('history', history)
        this.setKeyword(history['searchWords'])
        const hasHistory = true
        this.$emit('searchItem', history, hasHistory)
      } else {
        this.setKeyword('')
        this.placeholder = DEFAULT_PLACEHOLDER
        this.$emit('reset')
      }
    },
    closeKeyboard () {
      this.readonly = true
      this.validDelay()
    },
    // 搜索成功
    searchSuccess () {
      console.log('this.keyword', this.keyword)
      this.placeholder = this.keyword
      this.keyword = ''
    },
    // 保存历史数据
    saveHistory (history) {
      this.$store.dispatch('speech/addSearchHistory', {
        dataRegionId: this.dataRegion.id,
        ...history
      })
    },
    inputFocus () {
      const inputEl = this.$refs.refSearchInput.getElementsByTagName('input')[0]
      const len = this.keyword.length
      inputEl.focus()
      inputEl.selectionStart = len
      inputEl.selectionEnd = len
    }
  },
  mounted () {
    this.blurDelay = debounce(this.blur, 300)
    this.inputFocusDelay = debounce(this.inputFocus, 500)
    this.validDelay = debounce(() => {
      this.readonly = false
    }, 300)
    this.reset()
  },
  destroyed () {
  },
  watch: {
    keyword (newVal, oldVal) {
    },
    dataRegion (newVal, oldVal) {
      this.reset()
    },
    defaultKeyword (newVal) {
      this.keyword = newVal
    },
    showRecommended (newVal) {
      if (newVal) {
        window.history.pushState({ title: '输入框页面', url: '#' }, '输入框页面', '#')
      } else if (history.state.title === '输入框页面') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.search-input {
  padding: 8px 15px;
  .van-search {
    background-color: transparent;
    padding-left: 0;
    .van-search__content {
      border-radius: 5px 0 0 5px;
      background-color: #ffffff;
    }
    .van-search__action {
      background-color: #ffffff;
      height: 20px;
      border-radius: 0 5px 5px 0;
      padding: 7px 0;
      div {
        line-height: 20px;
        padding: 0 18px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #319DE2;
        border-left: 1px solid #D4D4D7;
      }
    }
  }
  .scroller-wrapper {
    // height: calc(100vh - 100px);
    position: relative;
  }
}
</style>
