<template>
  <div class="search-filter van-clearfix">
    <div class="search-filter-inner">
      <div class="matched-keyword-preview" v-if="keywordMatched.keywordList.length > 0">
        <template v-for="(item, index) in keywordList">
          <div class="keyword-item" :key="'keyword' + index" v-if="!item.implicit || !ignoreImplicit">
            <div class="keyword-input" :style="item.exclude?{'text-decoration': 'line-through'}: ''">{{ item['matchedValue'] || item['keywordInput'] }}</div>
            <div class="keyword-category">{{ item['kindDesc'] }}</div>
          </div>
        </template>
      </div>
      <van-icon class="matched-keyword-trigger" name="apps-o" @click="showPopup = true"/>
    </div>
    <date-selector ref="refDateSelector"/>
    <van-popup
      class="filter-popup"
      v-model="showPopup"
      position="right"
      closeable
      :close-icon="null"
      get-container="#app"
      :style="{ height: '100%', width: '80%' }">
      <div class="filter-popup-content">
        <scroller class="scroller" snapping>
          <van-collapse v-model="activeNames">
            <van-collapse-item name="keyword_parse">
              <strong slot="title">关键词解析结果</strong>
              <template v-for="(item, index) in keywordList">
                <van-cell
                  :key="'keyword' + index"
                  v-if="!item.implicit || !ignoreImplicit"
                  :value="item['kindDesc']">
                  <van-tag slot="title" color="#5b86e5" size="large">{{ item['matchedValue'] || item['keywordInput'] }}</van-tag>
                </van-cell>
              </template>
            </van-collapse-item>
            <van-collapse-item name="datetime">
              <strong slot="title">时间范围</strong>
              <van-radio-group v-model="datetimeReplaced" direction="horizontal">
                <van-cell
                  v-if="datetimeMatched"
                  clickable
                  @click="datetimeReplaced = datetimeMatched">
                  <van-radio :name="datetimeMatched">{{ datetimeMatched }}</van-radio>
                </van-cell>
                <van-cell
                  v-for="dtOption in datetimeOptions"
                  :key="dtOption['key']"
                  is-link
                  arrow-direction="down">
                  <div>
                    <van-popover
                      style="display: block;text-align: right"
                      v-model="dtOption.showSelector"
                      trigger="click"
                      :actions="dtOption.options"
                      @select="datetimeReplaced = dtOption.selected = $event.text"
                    >
                      <template #reference>
                        <div>
                          <van-radio slot="icon" :name="dtOption['selected']" style="pointer-events: none">{{ dtOption['selected'] }}</van-radio>
                        </div>
                      </template>
                    </van-popover>
                  </div>
                </van-cell>
              </van-radio-group>
            </van-collapse-item>
            <van-collapse-item
              v-for="filter in filters"
              :key="filter.key"
              :name="filter.key">
              <strong slot="title">{{ filter.label }}</strong>
              <van-checkbox-group v-model="filter['value']" direction="horizontal">
                <van-cell
                  v-for="item in filter['options']"
                  :key="item"
                  clickable>
                  <van-checkbox :name="item" shape="square">{{ item }}</van-checkbox>
                </van-cell>
              </van-checkbox-group>
            </van-collapse-item>
          </van-collapse>
        </scroller>
      </div>
      <div class="filter-popup-footer van-hairline--top">
        <van-button type="default" size="small" @click="showPopup = false" style="margin-right: 2em">关闭</van-button>
        <van-button type="primary" size="small" @click="onSubmit">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import DateSelector from './DateSelector'
import ReportChartData from '@/utils/reportData'
import _ from 'lodash'

export default {
  name: 'KeywordFilter',
  components: {
    DateSelector
  },
  props: {
    keyword: {
      type: String,
      default: ''
    },
    dataRegionId: {
      type: String,
      default: ''
    },
    keywordSearchHistoryId: {
      type: String,
      default: ''
    },
    keywordMatched: {
      type: Object,
      default() {
        return {
          keywordList: []
        }
      }
    },
    reportData: {
      type: Object,
      default() {
        return {
          suggest: {
            dimensions: [],
            metrics: []
          }
        }
      }
    },
    ignoreImplicit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeNames: ['keyword_parse', 'datetime'],
      showPopup: false,
      dataLoading: false,
      filtersCollapsed: true,
      reportChartData: {},
      datetimeCollapsed: true,
      datetimeMatched: '',
      datetimeReplaced: '',
      /**
       * {
       *  key: 'dimensions',
       *  label: '关联指标',
       *  collapsed: true,
       *  options: [],
       *  value: []
       * }
       */
      filters: [],
      datetimeOptions: [{
        key: 'dt_years',
        selected: '去年',
        showSelector: false,
        options: ['今年', '去年', '前年', '近2年', '近3年', '近5年', '近10年'].map(item => { return { text: item } })
      }, {
        key: 'dt_months',
        selected: '本月',
        showSelector: false,
        options: ['本月', '上个月', '上上个月', '近2个月', '近3个月', '近5个月', '近10个月'].map(item => { return { text: item } })
      }],
      searchHistory: []
    }
  },
  computed: {
    keywordList() {
      const { keywordList } = this.keywordMatched
      return keywordList || []
    }
  },
  methods: {
    onKeywordValueChange(data) { // 没执行？
      this.keywordList[data.index].matchedValue = data.value
      let _keyword = ''
      this.keywordList.forEach(item => {
        _keyword += item.matchedValue
      })
      this.$emit('speechKeywordSearch', _keyword)
    },
    getDimensionValues(filter) {
      const _conditionFields = this.reportData['suggest']['currentConditionFields']
      const _column = _conditionFields.find(field => field['aliasName'] === filter['key'])
      if (_column) {
        this.$api.dataset.getFieldValues(_column['id']).then(res => {
          res['data'].forEach(item => {
            if (item && item[filter['key']]) {
              filter['options'].push(item[filter['key']])
            }
          })
        })
      }
    },
    onSubmit() {
      const _keywords = []
      if (this.datetimeReplaced) _keywords.push(this.datetimeReplaced)
      this.filters.forEach(filter => {
        _keywords.push(...filter['value'])
      })
      this.$emit('submit', _keywords.join(' '))
      this.$store.dispatch('speech/setInputKeyword', _keywords.join(' '))
      this.showPopup = false
    },
    onDatetimeChange(value) {
      this.datetimeReplaced = value
      this.onSubmit()
    },
    parseReportData() {
      // console.log('parseReportData')
      // search history
      this.searchHistory.push({
        keyword: this.keyword,
        keywordMatched: _.cloneDeep(this.keywordMatched)
      })
      this.filters.splice(0)
      this.reportChartData = new ReportChartData(this.reportData)
      this.keywordMatched['keywordList'].forEach(keyword => {
        if (keyword['used']) {
          // 获取时间值
          if (keyword['keywordCategory'] === 'DATETIME') {
            this.datetimeMatched = keyword['matchedValue']
            this.datetimeReplaced = keyword['matchedValue']
          }

          if (keyword['keywordCategory'] === 'DIMENSION_VALUE') {
            const _existFilter = this.filters.find(filter => filter['key'] === keyword['relationKeyword'])
            if (_existFilter) {
              _existFilter['value'].push(keyword['matchedValue'])
            } else {
              const _filter = {
                key: keyword['relationKeyword'],
                label: keyword['relationKeyword'],
                collapsed: true,
                options: [],
                value: [keyword['matchedValue']]
              }
              this.filters.push(_filter)
              this.getDimensionValues(_filter)
            }
          }
        }
      })
      const _metrics = {
        key: 'metrics',
        label: '关联指标',
        collapsed: true,
        options: [...this.reportData['suggest']['metrics']],
        value: [...this.reportChartData['metrics']]
      }
      const _dimensionsOptions = [...this.reportData['suggest']['dimensions']]
      // add 时间维度
      const _indexDatetime = _dimensionsOptions.indexOf('日期')
      if (_indexDatetime > -1) {
        _dimensionsOptions.splice(_indexDatetime, 1, ...['日期', '月份', '年月', '年份'])
      }
      const _dimensions = {
        key: 'dimensions',
        label: '关联维度',
        collapsed: true,
        options: [..._dimensionsOptions],
        value: [...this.reportChartData['dimensions']]
      }
      this.filters.push(_metrics, _dimensions)
    }
  },
  watch: {
    reportData() {
      this.parseReportData()
    }
  },
  mounted() {
    this.parseReportData()
  }
}
</script>
<style lang="less" scoped>
.search-filter {
  background: #fff;
  padding: 3px 12px;

  .search-filter-inner {
    position: relative;
    padding-bottom: 6px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;

    .matched-keyword-preview {
      overflow: auto;
      white-space: nowrap;
      // text-overflow: ellipsis;

      .keyword-item {
        position: relative;
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        line-height: 1.2;

        &:first-child {
          padding-left: 0;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 20%;
          bottom: 20%;
          border-right: 1px solid #eee;
        }

        .keyword-input {
          font-size: 12px;
        }

        .keyword-category {
          font-size: 10px;
          color: #aaa;
        }
      }

      &::-webkit-scrollbar {
        display:none
      }
    }

    .matched-keyword-trigger {
      position: absolute;
      right: 0;
      top: 6px;
    }
  }
}
.filter-card {
  font-size: 14px;
  border-top: 1px solid #e8e8e8;
  padding: 6px 12px;

  &-title {
    position: relative;
    height: 30px;
    line-height: 30px;
  }

  &-content {
  }

  &-toggle {
    position: absolute;
    top: 6px;
    right: 0;
  }

  .collapsed {
    height: 26px;
    overflow: hidden;
  }

  .filter-value-checkbox {
    margin-top: 6px;
  }
}
.filter-popup {
  height: 100%;
  width: 80%;
  .filter-popup-content {
    position: relative;
    height: calc(100% - 46px);
  }
  .filter-popup-footer {
    background: #fefefe;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 32px;
    padding: 7px 12px;
    text-align: center;
  }
}
</style>
