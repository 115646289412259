<template>
  <div class="document-preview" v-if="active">
    <van-nav-bar
      :title="title"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="close"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <div class="document-preview-content" :style="{height: `calc(100vh - 90px - ${moreHeight}px)`}">
      <iframe
        class="document-preview-iframe"
        :src="url">
      </iframe>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'DocumentPreview',
  components: {
  },
  data() {
    return {
      active: false,
      url: '',
      title: ''
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight
    })
  },
  methods: {
    show (url, title) {
      this.title = title
      if (title === '文档') {
        // this.url = 'https://vw.usdoc.cn/?w=4&src=' + url
        // this.url = 'https://view.officeapps.live.com/op/view.aspx?src= ' + encodeURIComponent(url)
        this.url = 'https://view.xdocin.com/xdoc?_xdoc=' + url
      } else {
        this.url = url
      }
      this.active = true
    },
    close () {
      this.active = false
    }
  },
  mounted () {
    // this.url = this.$route.params.url
    // this.title = this.$route.params.title
  },
  watch: {
    active (newVal) {
      if (newVal) {
        window.history.pushState({ title: '文档预览', url: '#' }, '文档预览', '#')
      } else if (history.state.title === '文档预览') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less">
  .document-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    .van-nav-bar {
      background-image: url("~@/assets/background.png");
      background-size: 100%;
      background-repeat: no-repeat;
      // &::after {
        // border-bottom-width: 0
      // }
      .van-nav-bar__content {
        height: 40px;
        .van-nav-bar__title {
          font-size: 19px;
          font-weight: bold;
          color: #ffffff;
          font-family: PingFang SC;
        }
        .van-nav-bar__left {
          .van-icon-arrow-left {
            color: #000000;
            font-weight: bold;
            color: #ffffff;
            &::before {
              vertical-align: middle;
            }
          }
        }
      }
    }
    .document-preview-iframe {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
    }
  }
</style>
