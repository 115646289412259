<template>
  <div class="search-document">
    <div class="search-result">
      <template v-if="active">
        <template v-if="dataLoading">
          <van-loading />
        </template>
        <template v-if="!dataLoading">
          <template v-if="options['sources'].length === 0">
            <van-empty image="search" description="暂无数据" />
          </template>
          <template v-else>
            <video-live-card :options="options" :title="title"></video-live-card>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import VideoLiveCard from '@/components/video/LiveCard'

const deviceKeywords = [{
  device: '486486486',
  keywords: ['摄像头1', '调取1号监控', '1号监控', '直播1', '视频1']
}, {
  device: '468459482',
  keywords: ['摄像头2', '调取2号监控', '2号监控', '直播2', '视频2']
}]
const keywordsDevice = []
deviceKeywords.forEach(deviceKeyword => {
  deviceKeyword.keywords.forEach(keyword => {
    keywordsDevice.push({
      keyword,
      device: deviceKeyword.device
    })
  })
})
keywordsDevice.sort((a, b) => a['keyword'].length > b['keyword'].length ? -1 : 1)

export default {
  name: 'SearchInteraction',
  components: {
    VideoLiveCard
  },
  props: {
    keyword: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataLoading: false,
      title: '视频播放',
      keywordsDevice,
      device: '',
      options: {
        liveui: true,
        fluid: true,
        autoplay: true,
        controls: true,
        sources: []
      }
    }
  },
  methods: {
    getDevice () {
      this.dataLoading = true
      this.$api.privacy.liveAddress(this.device['device'])
        .then((data) => {
          this.prepared = true
          this.title = data['device']
          this.options.sources.splice(0)
          this.options.sources.push({
            src: data['url'].replace('http://', 'https://'),
            type: 'application/x-mpegURL'
          })
          this.dataLoading = false
        })
        .catch((err) => {
          this.dataLoading = false
          this.$message.error(err.response.data.message)
        })
    },
    searchDevice () {
      console.log('searchDevice!!!!!!!!!!!!!!!!!!!!!!!!!!!!', this.keyword)
      const _device = keywordsDevice.find(item => this.keyword.indexOf(item['keyword']) > -1)
      if (_device) {
        this.$emit('result', [_device])
        this.device = _device
        this.getDevice()
      } else {
        this.device = null
        this.options.sources.splice(0)
        this.$emit('result', [])
      }
    }
  },
  watch: {
    keyword: {
      handler () {
        console.log('................................................')
        this.searchDevice()
      },
      immediate: true
    }
  }
}
</script>
<style lang="less">
  .search-document {
    .search-keyword {
      display: flex;
      .search-input-wrapper {
        padding: 4px 6px;
        background: #fff;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
        flex: 1;
        display: flex;
        flex-direction: column;
        input {
          flex: 1;
          background: transparent;
          border: none;
          padding: 0;
          word-spacing: 3px;

          &:focus {
            outline: none;
          }
        }
      }
      .search-submit {
        width: 80px;
        margin-left: 3px;
      }
    }
    .search-result {
      .search-result-list {
        .ant-spin-nested-loading {
          min-height: 200px;
        }
        .ant-list-item-meta-title {
          >a {
            font-weight: bold;
          }
        }
      }
      .vjs-modal-dialog-content {
        overflow-wrap: break-word;
      }
    }
  }
</style>
