<template>
  <!-- <div v-long-press="startSelect" class="search-track card"> -->
  <div v-long-press="startSelect" :class="'search-track-' + layout + ' card'">
    <van-cell v-if="isSearchPage && type === 'collections'" @click="cardTitleClick" :value="dataIndex + 1 +'/' + dataList.length" class="card-title">
      <div slot="title">
        <!-- <van-icon name="good-job-o" /> -->
        <img src="@/assets/img/icon/fire.png" />
        <div class="search-track-title">{{ title }}</div>
      </div>
    </van-cell>
    <van-cell
      v-else
      :title="title"
      is-link
      arrow-direction="down"
      :value="dataList.length ? `${ dataIndex + 1 }/${ dataList.length }` : ''"
      class="search-track-title"
      @click="cardTitleClick"/>
    <!-- <div class="card-title">
      {{ title }}
      <span v-if="dataList.length" class="card-title-extra">
        {{ dataIndex + 1 }}/{{ dataList.length }}
      </span>
    </div> -->
    <div class="card-content" v-if="showContent">
      <van-swipe v-if="dataList.length" :autoplay="autoplay" :show-indicators="false" @change="dataIndex = $event">
        <van-swipe-item v-for="(items, index) in dataList" :key="index">
          <template v-if="layout === 'list'">
            <template v-for="(item, iconIndex) in items">
              <div :key="item['id']" class="cell-box">
                <div class="sort_icon" v-if="isSearchPage && type === 'collections'" :style="iconBackground(iconIndex + 1 + index * 6)"></div>
                <!-- <div class="sort_icon" v-if="isSearchPage && type === 'collections'" :style="iconBackground(iconIndex + 1 + index * 6)">{{ iconIndex + 1 + index * 6 }}</div> -->
                <van-cell
                  title-class="van-ellipsis"
                  :title="item['searchWords']"
                  is-link
                  @click="searchWords(item)"/>
              </div>
            </template>
          </template>
          <template v-else>
            <van-grid
              :border="false"
              :column-num="cols"
              :gutter="8"
              class="thumbs-wrapper"
              :center="false"
              clickable>
              <van-grid-item v-for="item in items" :key="item.id" @click="searchWords(item)">
                <div class="search-item">
                  <div class="search-item-inner">
                    <div class="search-thumb-box">
                      <div class="search-thumb" :style="{backgroundImage: 'url(/api' + item['thumbnail'] + ')'}"></div>
                    </div>
                    <div class="search-words">{{ item['searchWords'] }}</div>
                  </div>
                  <div v-if="selectHistories" class="search-item-action" @click.stop>
                    <van-button type="info" size="mini" @click="cancelSelect">取消</van-button>
                    <van-button type="danger" size="mini" @click="deleteItem(item)">删除</van-button>
                  </div>
                </div>
              </van-grid-item>
            </van-grid>
          </template>
        </van-swipe-item>
      </van-swipe>
      <van-empty v-else image="search" description="暂无数据" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import _ from 'lodash'

export default {
  name: 'SearchTrackCard',
  components: {
  },
  props: {
    title: {
      type: String,
      default: '您可能搜索'
    },
    keyword: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'recent' // collections / recent / suggests / headlines
    },
    layout: {
      type: String,
      default: 'list' // list / thumb
    },
    autoplay: {
      type: Number,
      default: 0
    },
    cols: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 5
    },
    total: {
      type: Number,
      default: 30
    },
    isSearchPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataLoading: false,
      originData: {},
      dataList: [],
      dataIndex: 0,
      totalPages: 0,
      currentPage: 0,
      pageSize: 30,
      totalElements: 0,
      showContent: false
    }
  },
  computed: {
    ...mapState({
      dataRegion: state => state.dataRegion.activeItem,
      inputKeyword: state => state.speech.inputKeyword,
      selectHistories: state => state.speech.selectHistories
    })
  },
  methods: {
    cardTitleClick () {
      this.showContent = !this.showContent
    },
    randomPage () {
      if (this.totalPages <= 1) return
      let _page = this.currentPage
      while (_page === this.currentPage) {
        _page = Math.floor(Math.random() * this.totalPages)
      }
      this.currentPage = _page
      this.getDataList()
    },
    loadData () {
      const { keyword } = this
      const dataRegionId = this.dataRegion.id
      if (!dataRegionId) return
      this.dataLoading = true
      console.log('loadData')
      const params = {
        dataRegionId,
        thumbnail: this.layout === 'thumb',
        page: 0,
        size: this.total
      }
      if (keyword) params.words = keyword
      this.$api.speech.searchTrackList(this.type, params).then(res => {
        this.originData = res
        this.formatData()
        this.dataLoading = false
      }).catch((error) => {
        this.dataLoading = false
        console.log(error)
      })
    },
    formatData () {
      const { number, totalElements, content, totalPages } = this.originData
      if (content) { // 在input中搜索有时候没有内容返回
        content.forEach(item => {
          if (item['searchWords'] && _.isArray(item['searchWords'])) item['searchWords'] = item['searchWords'].join('|')
          if (item['words']) item['searchWords'] = item['words']
        })
      }
      this.dataList = _.chunk(content, this.cols * this.rows)
      this.totalPages = totalPages
      this.totalElements = totalElements
      this.currentPage = number
    },
    searchWords (item) {
      const { dataRegionId } = item
      if (dataRegionId !== this.dataRegion.id) {
        this.$store.commit('dataRegion/setActiveId', dataRegionId)
      }
      this.$emit('searchItem', item)
    },
    startSelect () {
      this.$store.dispatch('speech/setSelectHistories', true)
    },
    cancelSelect () {
      this.$store.dispatch('speech/setSelectHistories', false)
    },
    deleteItem (item) {
      this.$api.speech.deleteSearchTrack(this.type, item['id']).then(res => {
        const { content } = this.originData
        const _index = content.findIndex(i => i['id'] === item['id'])
        if (_index > -1) content.splice(_index, 1)
        console.log(item)
        this.formatData()
      })
    },
    refreshSearch () {
      return new Promise((resolve, reject) => {
        if (this.originData.content) {
          var randomItem = this.originData.content[Math.floor(Math.random() * this.originData.content.length)]
          this.searchWords(randomItem)
        }
        resolve()
      })
    },
    iconBackground (index) {
      return { 'background-image': 'url(' + require('@/assets/img/icon/arrow.png') + ')' }
      // const orderIndex = index > 4 ? 4 : index
      // return { 'background-image': 'url(' + this.listBackground[orderIndex - 1] + ')' }
    }
  },
  mounted() {
    if (this.type === 'collections') {
      this.$eventBus.$on('FrequentSearchChange', this.loadDataDelay)
    }
    this.showContent = true
    this.loadData()
  },
  created () {
    this.loadDataDelay = debounce(this.loadData, 300)
    this.$eventBus.$on('SearchSuccess', this.loadDataDelay)
  },
  destroyed () {
    this.$eventBus.$off('SearchSuccess')
    if (this.type === 'collections') {
      this.$eventBus.$off('FrequentSearchChange')
    }
  },
  watch: {
    dataRegion () {
      this.loadDataDelay()
    },
    keyword () {
      this.loadDataDelay()
    }
  }
}
</script>
<style lang="less">
.search-track-list {
  .van-grid-item__content {
    padding: 0;
  }
  .card-content {
    padding: 12px 0;
  }
}

.search-track-thumb {
  background-color: #ffffff;
  // margin: 0 15px;
  // margin: 0 15px 6px;
  border-radius: 10px;

  .search-track-title {
    background-color: transparent;
    border: none;
    font-size: 15px;
    padding: 10px 12px;

    &::after {
      border: none;
    }
  }

  .card-content {
    padding: 0 4px 20px;
    background-color: transparent;

    .van-grid-item__content {
      padding: 0;
      background: #FFFFFF;
      border-radius: 5px;
      margin: 0;

      .search-item {
        // padding: 10px;
        // position: relative;
        .search-thumb-box {
          border: 1px solid #F2F2F5;
          border-radius: 5px;
          padding: 5px;
          margin-bottom: 8px;
          .search-thumb {
            border: none;
            padding-top: 84%;
            background-size: cover;
            background-position: center;
          }
        }
        .search-words {
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #1F1F1F;
          line-height: 18px;
          height: 36px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        &-action {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
