<template>
  <div class="search-comment">
    <div style="padding: 9px 5px;">
      <p class="feedback-ask">请问您对本次搜索结果是否满意？</p>
      <van-row >
        <van-col :span="8" class="feedback-item satisfied" >
          <div @click="submitFeedback('1')" :class="{'choose-satisfied': form.feedback==='1', 'choose-unsatisfied': form.feedback==='2'}">
            <img src="@/assets/img/icon/satisfied_blue.png" v-if="form.feedback==='1'" />
            <img src="@/assets/img/icon/satisfied.png" v-else />满意
          </div>
        </van-col>
        <van-col :span="8" class="feedback-item unsatisfied">
          <div @click="submitFeedback('2')" :class="{'choose-satisfied': form.feedback==='1', 'choose-unsatisfied': form.feedback==='2'}">
            <!-- <van-icon name="smile-o" /> 不满意 -->
            <img src="@/assets/img/icon/sad_blue.png" v-if="form.feedback==='2'" />
            <img src="@/assets/img/icon/sad.png" v-else />不满意
          </div>
        </van-col>
        <van-col :span="8" class="feedback-item">
          <div @click="onAdvice">
            <div class="advice-btn">
              <van-icon name="comment-o" />建议
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showCommentDialog" title="反馈意见" show-cancel-button @confirm="submmitComment" @cancel="cancleComment">
      <van-field
        v-model="form.comment"
        rows="1"
        type="textarea"
        placeholder="请问有什么问题和建议呢？可以留言给我们哦~"
      />
    </van-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'SearchComment',
  props: {
    // keywordSearchHistoryId
    searchId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      finished: false,
      keywordList: [],
      form: {
        feedback: '',
        comment: ''
      },
      showCommentDialog: false
    }
  },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id
    })
  },
  methods: {
    submitFeedback (type) {
      this.form.feedback = type
      const data = {
        feedbackContent: '',
        satisfaction: this.form.feedback === '1',
        searchHistoryId: this.searchId
      }
      this.loading = true
      this.$api.speech.postFeedback(data).then(res => {
        this.$toast.success('提交评价成功')
      }).finally(() => {
        this.loading = false
      })
    },
    resetForm () {
      this.form.feedback = ''
      this.form.comment = ''
    },
    submmitComment() {
      const data = {
        feedbackContent: this.form.comment,
        satisfaction: null,
        searchHistoryId: this.searchId
      }
      this.loading = true
      this.$api.speech.postFeedback(data).then(res => {
        this.$toast.success('提交建议成功')
      }).finally(() => {
        this.loading = false
        history.back()
      })
    },
    cancleComment () {
      this.form.comment = ''
      history.back()
    },
    onAdvice () {
      this.showCommentDialog = true
      window.history.pushState({ title: '建议', url: '#' }, '建议', '#')
    }
  },
  mounted() {
  },
  watch: {
    searchId () {
      this.resetForm()
    },
    dataRegionId () {
      this.resetForm()
    }
  }
}
</script>
<style lang="less" scoped>
.search-comment {
  div {
    background-color: #fff;
    .feedback-ask {
      margin: 0;
      font-size: 15px;
      padding: 0px 7px 15px;
    }
    .feedback-item {
      div {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 28px;
        i {
          font-size: 24px;
          margin-right: 5px;
        }
      }
    }
    .satisfied {
      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
      .choose-satisfied {
        color: #333333;
      }
      .choose-unsatisfied {
        color: #C8C8C8;
      }
    }
    .unsatisfied {
      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
      .choose-satisfied {
        color: #C8C8C8;
      }
      .choose-unsatisfied {
        color: #333333;
      }
    }
    .advice-btn {
      padding: 0 18px;
      background: #319DE2;
      border-radius: 15px;
      color: #ffffff;
      i {
        font-size: 18px !important;
      }
    }
  }
}
</style>
