<template>
  <div
    ref="refSpeechIndex"
    class="speech-index"
    :style="showDefaultContent ?
      {'padding-top': statusBarHeight + 'px',
       'background-size': '100% 75%',
       'background-image': 'url(' + require('@/assets/search-background.png') + ')'} :
      {'padding-top': statusBarHeight + 'px',
       'background-size': '100% 40%',
       'background-image': 'url(' + require('@/assets/background.png') + ')'}" >
    <SearchInput
      ref="refSearchInput"
      :addInputText="addInputText"
      :defaultKeyword="keyword"
      @search="parseKeyword"
      @reset="reset"
      @searchItem="searchItem" />
    <div class="scroller-wrapper" ref="refScrollerWrapper" :style="{height: `calc(100vh - 110px - ${moreHeight}px)`}">
      <div v-show="showSearchResult" class="search-result-wrapper">
        <scroller
          :onInfinite="onInfinite"
          :onRefresh="onRefreshScroller"
          ref="my_scroller"
        >
          <div ref="refScrollerContent" :style="{minHeight: `calc(100vh - 110px - ${moreHeight}px)`}">
            <div class="speech-view-box" v-for="item in tabList" :key="item['key']">
              <van-cell v-if="item['key'] !== 'speech'" :title="item['tab']" is-link arrow-direction="down" @click="item['showContent'] = !item['showContent']"/>
              <div>
                <template v-if="item['key'] === 'speech'">
                  <SearchSpeech
                    ref="refSearchSpeech"
                    :active="item['showContent']"
                    @showPage="showPage"
                    @result="onResult(arguments, 'speech')"
                    @getChartData="getChartData"
                    @changeSearchWord="changeSearchWord"
                    @ifShowContent="item['showContent'] = !item['showContent']"/>
                </template>
                <template v-if="item['key'] === 'document'">
                  <search-document
                    key="document"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    index-name="wdss2"
                    @result="onResult(arguments, 'document')" />
                </template>
                <template v-if="item['key'] === 'interaction'">
                  <search-interaction
                    key="interaction"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    @result="onResult(arguments, 'interaction')" />
                </template>
                <template v-if="item['key'] === 'web'">
                  <search-document
                    key="web"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    index-name="webinfo"
                    @result="onResult(arguments, 'web')" />
                </template>
                <!-- </div> -->
              </div>
            </div>
            <template v-if="showSearchResult">
              <!-- <div class="speech-view-box" v-if="keywordMatched">
                <SpeechRecommentWord :keyword="keywordMatched.keyword" :keywordMatchedList="keywordMatched.keywordList" @recommentActiveWord="getRecommentActiveWord" @FilterParseKeyword="FilterParseKeyword"/>
              </div> -->
              <div class="speech-view-box">
                <SearchComment ref="searchComment" :searchId="keywordSearchHistoryId" />
              </div>
              <div class="speech-view-box">
                <SearchTrackCard
                  ref="collectionsTrackCards"
                  title="推荐搜索"
                  type="collections"
                  layout="thumb"
                  :cols="3"
                  :rows="2"
                  @searchItem="searchItem" />
              </div>
              <SearchTrackCard
                v-show="false"
                ref="recentTrackCard"
                title="最近搜索"
                type="recent"
                layout="thumb"
                :cols="3"
                :rows="2"
                @searchItem="searchItem" />
            </template>
          </div>
          <div slot="infinite-spinner" style="font-size: 14px">{{ infiniteText }}</div>
        </scroller>
        <div class="left-arrow" @click="clickLeftArrow" v-show="!!historyLocations[activeDataRegion.id]"><van-icon name="arrow-left" /></div>
        <div class="right-arrow" @click="clickRightArrow" v-show="searchHistories[activeDataRegion.id] && (historyLocations[activeDataRegion.id] !== searchHistories[activeDataRegion.id].length - 1)"><van-icon name="arrow"/></div>
        <RecommentsChooseDialog
          v-if="keywordMatched"
          ref="RecommentChooseDialog"
          @submit="FilterParseKeyword"
          :recommentActiveWord="recommentActiveWord"
          :dataRegionId="activeDataRegion.id"
          :keywordMatched="keywordMatched"
          :reportData="originReportData"
          :ignoreImplicit="ignoreImplicit"/>
      </div>
      <div v-if="showDefaultContent" class="search-result-home">
        <van-pull-refresh
          v-model="isRefresh"
          success-text="刷新成功"
          :head-height="60"
          @refresh="onRefresh"
        >
          <div style="height: 100%;overflow: auto" class="search-recommend-content">
            <!-- <RelationshipDiagram @clickDiagramLabel="clickDiagramLabel" v-if="diagramHeight" :height="diagramHeight" /> -->
            <SearchTrackCard
              ref="collectionsTrackCards"
              title="推荐搜索"
              type="collections"
              layout="list"
              :cols="3"
              :rows="2"
              :isSearchPage="true"
              @searchItem="searchItem" />
            <SearchTrackCard
              v-show="false"
              ref="recentTrackCard"
              title="最近搜索"
              type="recent"
              layout="thumb"
              :cols="3"
              :rows="2"
              @searchItem="searchItem" />
          </div>
        </van-pull-refresh>
        <KeywordSelectDialog ref="refKeywordSelectDialog" @search="parseKeyword"/>
      </div>
    </div>
    <WxShareTips ref="refWxShareTips" />
    <AndroidShareTips ref="refAndroidShareTips" />
    <DocumentPreview ref="refDocumentPreview" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
// import _ from 'lodash'
// import SpeechView from '@/components/speech/SpeechView'
import KeywordFilter from '@/components/speech/KeywordFilter'
import DataRegionPopover from '@/components/global/DataRegionPopover'
import SearchComment from '@/components/speech/SearchComment'
import SpeechRecommentWord from '@/components/speech/SpeechRecommentWord'
import RecommentsChooseDialog from '@/components/speech/RecommentsChooseDialog'
// import KeywordMatched from '@/utils/keywordMatched'
// import ReportChartData from '@/utils/reportData'
import SearchInput from '@/components/speech/SearchInput'
import SearchTrackCard from '@/components/speech/SearchTrackCard'
import RelationshipDiagram from '@/components/speech/RelationshipDiagram'
import KeywordSelectDialog from '@/components/speech/KeywordSelectDialog'
// import RichReportComponent from '@/views/richReport/view/RichReportComponent'
import WxShareTips from '@/components/global/WxShareTips'
import AndroidShareTips from '@/components/global/AndroidShareTips'
import SearchDocument from '@/components/search/SearchDocument'
import SearchInteraction from '@/components/search/SearchInteraction'
import DocumentPreview from '@/components/search/DocumentPreview'
import SearchSpeech from '@/components/search/SearchSpeech'

const SEARCH_STATUS = {
  WAITING: 0,
  INPUTTING: 1,
  PARSING: 2,
  REPORTING: 3,
  RENDERING: 4
}
export default {
  name: 'SpeechIndex',
  components: {
    SearchInput,
    KeywordFilter,
    // SpeechView,
    SearchComment,
    SpeechRecommentWord,
    RecommentsChooseDialog,
    DataRegionPopover,
    SearchTrackCard,
    RelationshipDiagram,
    KeywordSelectDialog,
    // RichReportComponent,
    WxShareTips,
    AndroidShareTips,
    SearchDocument,
    SearchInteraction,
    DocumentPreview,
    SearchSpeech
  },
  data() {
    return {
      isRefresh: false,
      SEARCH_STATUS,
      // status: '',
      // chartType: '',
      keywordInputFocus: false,
      // options: [],
      hasSearchResult: false,
      keywordMatched: null,
      // benchmarkingCategory: [],
      // keywordParse: {
      //   keywordMatch: [],
      //   reportDataRequest: {}
      // },
      // reportChartData: {},
      // origin: {
      //   keywordParse: {
      //     keywordMatch: [],
      //     reportDataRequest: {}
      //   },
      //   reportData: {}
      // },
      originReportData: {},
      keywordSearchHistoryId: null,
      // 本次交互关键词输入【回车】列表
      // keywordSession: [],
      ignoreImplicit: true,
      addInputText: '',
      infinitePositoin: 0,
      infiniteText: '上推返回初始页...',
      contentHeight: 0,
      minContentHeight: 0,
      showContent: true,
      diagramHeight: 0,
      // diagramMarginBottom: 0,
      historyLocation: 0,
      historyDataList: [],
      // smartType: 'COMMON_SEARCH', // 判断是正常图表还是分析报告
      // richModel: {},
      // richReportNotSaveHistory: false,
      recommentActiveWord: [],
      tabList: [{
        key: 'speech',
        tab: '数据',
        originTab: '数据',
        result: 0,
        showContent: true,
        rendered: false
      }, {
        key: 'interaction',
        tab: '互联',
        originTab: '互联',
        result: 0,
        showContent: false,
        rendered: false
      }, {
        key: 'document',
        tab: '文档',
        originTab: '文档',
        result: 0,
        showContent: false,
        rendered: false
      }, {
        key: 'web',
        tab: '网页',
        originTab: '网页',
        result: 0,
        showContent: false,
        rendered: false
      }],
      searchKeyWord: ''
      // resultTabCount: 0
    }
  },
  computed: {
    ...mapState({
      speechModalDisplay: state => state.speech.speechModalDisplay,
      activeDataRegion: state => state.dataRegion.activeItem,
      // keyword: state => state.speech.inputKeyword,
      inputStatus: state => state.speech.inputStatus,
      INPUT_STATUS_OPTION: state => state.speech.INPUT_STATUS_OPTION,
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight,
      searchHistories: state => state.speech.searchHistories,
      historyLocations: state => state.speech.historyLocations
    }),
    // historyLocation: {
    //   get () {
    //     return this.$store.state.speech.historyLocations[this.activeDataRegion.id] || 0
    //   },
    //   set (val) {
    //     this.$store.commit('speech/setHistoryLocation', { dataRegionId: this.activeDataRegion.id, historyLocation: val })
    //   }
    // },
    speechRecognizedKeyword: {
      get () {
        return this.$store.state.speech.speechRecognizedKeyword
      },
      set (val) {
        this.$store.commit('speech/setSpeechRecognizedKeyword', val)
      }
    },
    keyword: {
      get () {
        return this.$store.state.speech.inputKeyword
      },
      set (val) {
        this.$store.commit('speech/setInputKeyword', val)
      }
    },
    showDefaultContent () {
      return this.inputStatus === this.INPUT_STATUS_OPTION.IDLE && !this.hasSearchResult && this.showContent
    },
    showSearchResult () {
      return this.inputStatus === this.INPUT_STATUS_OPTION.IDLE && this.hasSearchResult && this.showContent
    }
  },
  methods: {
    ...mapActions('speech', [
      'showSpeechModal',
      'hideSpeechModal'
    ]),
    clearSearchContent () {
      this.changeHistoryLocation(-1)
      this.$refs.refSearchInput.reset()
      this.hasSearchResult = false
    },
    onRefresh() {
      this.$refs.recentTrackCard.refreshSearch().then(() => {
        this.isRefresh = false
      })
    },
    onRefreshScroller(done) {
      this.$refs.recentTrackCard.refreshSearch().then(() => {
        done()
      })
    },
    onInfinite(done) {
      const scrollHeight = this.$refs.refScrollerContent.scrollHeight
      if (this.contentHeight !== scrollHeight) { // 当高度发生变化时，重设contentHeight
        const minus = scrollHeight - this.contentHeight
        this.infinitePositoin += minus
        this.contentHeight = scrollHeight
      } else if (this.minContentHeight === this.contentHeight) { // 当内容和滚动高度相等，且最小高度和内容相等时，不触发上推事件
        this.infinitePositoin = 0
      }
      const positionTop = this.$refs.my_scroller.getPosition().top
      this.infinitePositoin = Math.min(this.infinitePositoin, positionTop)
      if (positionTop - this.infinitePositoin > 80) {
        this.infiniteText = '松开返回初始页...'
      } else {
        this.infiniteText = '上推返回初始页...'
      }
      setTimeout(() => {
        this.$refs.my_scroller?.scrollTo(0, this.infinitePositoin - 1, true)
      }, 10)
      done()
    },
    searchItem (history, hasHistory) { // hasHistory 从别处切换回来有历史数据，不显示主页const { searchWords, chartType } = history
      this.$refs['refSearchSpeech'][0].searchItem(history, hasHistory)
    },
    parseKeyword () {
      this.searchKeyWord = this.keyword
      this.$refs['refSearchSpeech'][0].parseKeyword()
    },
    reset () {
      this.$refs['refSearchSpeech'][0].reset()
    },
    clickDiagramLabel (label) {
      this.keyword += label
    },
    ifClearSearch () {
      if (this.infiniteText === '松开返回初始页...') {
        this.infiniteText = '上推返回初始页...'
        this.clearSearchContent()
        if (!this.diagramHeight) {
          this.$nextTick(() => {
            this.getRelationshipsHeight()
          })
        }
      }
    },
    getRelationshipsHeight () {
      const trackCardHeight = 286 // 加上固定部分高度
      this.diagramHeight = this.$children.find(child => child.$el._prevClass === 'van-pull-refresh').$el.offsetHeight - trackCardHeight - 4
      if (this.diagramHeight < 140) this.diagramHeight = 140
    },
    FilterParseKeyword (params) {
      this.parseKeyword(params)
    },
    clickLeftArrow () {
      this.$refs['refSearchSpeech'][0].clickLeftArrow()
    },
    clickRightArrow () {
      this.$refs['refSearchSpeech'][0].clickRightArrow()
    },
    changeHistoryLocation (historyLocation) {
      this.$store.commit('speech/setHistoryLocation', { dataRegionId: this.activeDataRegion.id, historyLocation })
    },
    popstateListener(e) {
      const keywordSelectDialog = this.$refs['refKeywordSelectDialog']
      const refSearchInput = this.$refs['refSearchInput']
      const refSpeechView = this.$refs['refSearchSpeech'][0].$refs['speechView'][0]
      const refDocumentPreview = this.$refs['refDocumentPreview']
      // const speechSmartChart = refSpeechView
      const speechSmartChart = refSpeechView?.$refs['speechSmartChart']?.find(item => item.uuid === refSpeechView.activeTabKey)
      if (keywordSelectDialog && keywordSelectDialog.visible) { // 搜索首页关键词选择弹窗
        keywordSelectDialog.visible = false
      } else if (refSearchInput.showRecommended) { // 输入框页面
        refSearchInput.onCancel()
        refSearchInput.$el.getElementsByClassName('van-field__control')[0].blur()
      } else if (refDocumentPreview.active) {
        refDocumentPreview.active = false
      } else if (speechSmartChart) { // 搜索结果页
        const searchComment = this.$refs['searchComment']
        const keywordFilterIcon = speechSmartChart.$refs['keywordFilterIcon']
        const refEarlyWarningDialog = speechSmartChart.$refs['refEarlyWarningDialog']
        const refPredictPopup = speechSmartChart.$refs['refPredictPopup']
        const refBenchmarkingPopup = speechSmartChart.$refs['refBenchmarkingPopup']
        if (speechSmartChart.showAction) { // 操作选项弹窗
          speechSmartChart.showAction = false
        } else if (keywordFilterIcon.showPopup) { // 词分按钮弹窗
          const dtOption = keywordFilterIcon.datetimeOptions.find(dtOption => dtOption.showSelector)
          if (dtOption) {
            dtOption.showSelector = false
          } else if (!history.state.title) {
            keywordFilterIcon.showPopup = false
          }
        } else if (history.state.title === '图表选项') { // 点击操作选项出来的弹窗
          if (refPredictPopup && refPredictPopup.visible) { // 预测弹窗
            refPredictPopup.visible = false
            history.back() // 回退两次包括操作选项的历史
          } else if (refBenchmarkingPopup && refBenchmarkingPopup.visible) { // 对标弹窗
            refBenchmarkingPopup.visible = false
            history.back() // 回退两次包括操作选项的历史
          } else if (refEarlyWarningDialog.visible) { // 通知预警弹窗
            refEarlyWarningDialog.visible = false
            history.back() // 回退操作选项的历史
          }
        } else if (history.state.title === '预警编辑') { // 点击操作选项出来的弹窗
          if (refEarlyWarningDialog.visible) { // 通知预警弹窗
            const refConditionSetting = refEarlyWarningDialog.$refs['refConditionSetting'].$refs['refConditionSettingpopup']
            if (refConditionSetting.showCreateCondition) { // 条件设置弹窗
              refConditionSetting.showCreateCondition = false
            }
          }
        } else if (searchComment.showCommentDialog) { // 意见建议弹窗
          searchComment.showCommentDialog = false
        }
      }
    },
    getRecommentActiveWord (recommentActiveWord) {
      this.recommentActiveWord = recommentActiveWord
    },
    onResult (params, key) {
      const result = params[0]
      const keyword = params[1]
      // this.activeTabKey = 'speech'
      if (keyword) this.keyword = keyword
      const tabIndex = this.tabList.findIndex(item => item['key'] === key)
      const tab = this.tabList[tabIndex]
      // tab['tab'] = `${tab['originTab']}（${result.length}）`
      tab['result'] = result ? result.length : 0
      // tab['showContent'] = false
      tab['rendered'] = true
      // this.resultTabCount += 1
      // 结果数量大于等于总tab时，决定跳转到哪一个tab
      // if (this.resultTabCount >= this.tabList.length) {
      if (this.tabList.findIndex(item => item['rendered'] === false) === -1) {
        this.tabList.forEach(item => {
          item['showContent'] = false
        })
        // if (this.resultTabCount >= this.tabList.length) {
        // this.resultTabCount = 0
        const activeTab = this.tabList.find(item => item['result'] > 0)
        if (activeTab) {
          this.hasSearchResult = true
          activeTab['showContent'] = true
        }
        this.$nextTick(() => {
          this.$refs.my_scroller?.scrollTo(0, 0)
        })
      }
    },
    showPage (hasSearchResult, showContent) {
      if (hasSearchResult || hasSearchResult === false) this.hasSearchResult = hasSearchResult
      if (showContent || showContent === false) this.showContent = showContent
    },
    getChartData (params) {
      if (params.keywordMatched) this.keywordMatched = params.keywordMatched
      if (params.keywordSearchHistoryId) this.keywordSearchHistoryId = params.keywordSearchHistoryId
      if (params.originReportData) this.originReportData = params.originReportData
      if (params.ignoreImplicit || params.ignoreImplicit === false) this.ignoreImplicit = params.ignoreImplicit
    },
    changeSearchWord (searchKeyWord) {
      this.searchKeyWord = searchKeyWord
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.minContentHeight = this.$refs.refScrollerWrapper.offsetHeight // 最小内容高度
    })
    window.addEventListener('touchend', this.ifClearSearch)
    window.addEventListener('popstate', this.popstateListener, false)

    this.$eventBus.$on('SPEECH_VOICE_KEYWORD', (keyword) => {
      this.keyword = keyword
      this.parseKeyword()
    })
    const historyData = this.$route.params.historyData
    const dataRequest = this.$route.params.dataRequest
    this.$refs['refSearchSpeech'][0].showChartDetail(historyData, dataRequest) // 根据参数判断是否为查看图表详情跳转

    if (this.$route.params.keyword) { // 设置首页搜索有关键词时存在
      this.keyword = this.$route.params.keyword // 默认为首页时进入直接跳转
      this.showContent = false
      this.parseKeyword()
    }
    this.$api.earlyWarning.getUnreadNum().then(res => {
      this.$eventBus.$emit('unreadNum', res.unread)
    })
    if (this.speechRecognizedKeyword) {
      this.showContent = false
      this.keyword = this.speechRecognizedKeyword
      this.parseKeyword()
      this.speechRecognizedKeyword = ''
    } else { // 显示知识图谱，才需要计算高度
      this.getRelationshipsHeight()
    }
  },
  watch: {
    searchKeyWord () {
      this.tabList.forEach(item => {
        item['rendered'] = false
      })
    }
  },
  destroyed() {
    this.$eventBus.$off('SPEECH_VOICE_KEYWORD')
    window.removeEventListener('touchend', this.ifClearSearch)
    window.removeEventListener('popstate', this.popstateListener)
  }
}
</script>
<style lang="less" scoped>
  .speech-index {
    position: relative;
    // height: calc(100vh - 50px);
    background-repeat: no-repeat;
    .history-keyword {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 999;
    }
    .loading-center {
      padding: 0.5em;
      background: #fff;
      text-align:center
    }
    .scroller-wrapper {
      // height: calc(100vh - 110px);
      position: relative;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      ::-webkit-scrollbar { width: 0 !important }
      .search-result-wrapper {
        .speech-view-box {
          background: #fff;
          margin: 0 15px 10px;
          border-radius: 10px;
          overflow: hidden;
        }
        .left-arrow, .right-arrow {
          width: 50px;
          height: 60px;
          // background-color: #2a2a2a5e;
          position: fixed;
          top: 230px;
          .van-icon {
            color: #dddddd;
            position: relative;
            top: 15px;
            left: 10px;
            font-size: 30px;
          }
        }
        .left-arrow {
          left: 0px;
        }
        .right-arrow {
          right: 0px;
        }
      }
      .search-result-home, .van-pull-refresh {
        height: 100%;
      }
    }
  }
</style>
<style lang="less">
  .speech-index {
    .search-result-home {
      .van-pull-refresh__head {
        color: #ffffff;
      }
    }

    .scroller-wrapper {
      .search-result-wrapper {
        .pull-to-refresh-layer {
          .text {
            color: #ffffff !important;
          }
          .arrow polygon, .arrow rect {
            fill: #ffffff !important;
          }
        }
        .loading-layer {
          height: 0 !important;
        }
      }
      .search-recommend-content {
        .search-track-list {
          background-size: 100%;
          background-image: url("~@/assets/card-background.png");
          margin: 0 15px 15px;
          // margin: 0 15px 6px;
          border-radius: 10px;
          .card-title {
            height: 35px;
            border: none;
            padding: 8px 15px 6px;
            background-color: transparent;
            .van-cell__title {
              img {
                width: 15px;
                height: 17px;
                vertical-align: text-bottom;
                margin-right: 10px;
              }
              .search-track-title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                display: inline-block;
              }
            }
            &::after {
              border: none;
            }
          }

          .card-content {
            padding: 5px 0 15px;
            background-color: transparent;
            .van-swipe {
              .van-swipe-item {
                .cell-box {
                  display: flex;
                  align-items: center;
                  padding: 0 17px;
                  .sort_icon {
                    display: inline-block;
                    margin: 4px 0;
                    width: 15px;
                    height: 15px;
                    background-size: 100% 100%;
                    text-align: center;
                    color: #ffffff;
                    font-size: 14px;
                  }
                  .van-cell {
                    width: calc(100% - 15px);
                    padding: 6px 0;
                    margin-left: 10px;
                    display: inline-block;
                    background-color: transparent;
                    position: relative;
                    .van-cell__right-icon {
                      display: none;
                    }
                  .van-cell__title::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(0.5);
                    transform: scaleY(0.5);
                  }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
