<template>
  <van-overlay :show="visible" @click="visible = false" class="Keyword-select-dialog">
    <div class="wrapper" :style="{'margin-top': statusBarHeight + 8 + 'px'}" v-if="visible">
      <div @click.stop class="search-input">
        <van-search
          v-model="keyword"
          :show-action="true"
          placeholder="请输入搜索关键词"
          @search="$emit('search', keyword)"
        >
          <template #action>
            <div @click="$emit('search', keyword)">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="dialog-box" @click.stop @touchmove.stop>
        <div class="dialog-title">
          <!-- <div class="title-left-text">关键词分类</div> -->
          <van-search
            class="title-search"
            v-model="searchValue"
            show-action
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
            <template #action>
              <div @click="onRefresh"><van-icon name="replay" /></div>
            </template>
          </van-search>
        </div>
        <van-tabs
          v-model="menuActiveIndex"
          class="dialog-content"
          color="#1264ba">
          <van-tab
            :title="tab.text"
            v-for="(tab, index) in tabLlistData"
            :key="index"
            :name="index">
            <div class="tab-content" :style="{height: treeSelectHeight+ 'px'}">
              <div class="tab-content-left">
                <van-list
                  :key="'left' + index"
                  v-model="tab.leftObject['loading']"
                  :finished="tab.leftObject['finished']"
                  :error.sync="tab.leftObject['error']"
                  error-text="请求失败，点击重新加载"
                  finished-text="没有更多了"
                  @load="onLoad(tab, tab.leftObject)"
                >
                  <van-cell v-for="item in tab.leftObject['children']" :key="item.id" :title="item.keywordInput" @click="clickItem(tab, tab.leftObject, item)" :class="{'active': item.active, 'selected': item.selected}"/>
                </van-list>
              </div>
              <div class="tab-content-right">
                <van-list
                  :key="'right' + index"
                  v-model="tab.rightObject['loading']"
                  :finished="tab.rightObject['finished']"
                  :error.sync="tab.rightObject['error']"
                  error-text="请求失败，点击重新加载"
                  finished-text="没有更多了"
                  @load="onLoad(tab, tab.rightObject)"
                >
                  <van-cell v-for="item in tab.rightObject['children']" :key="item.id" :title="item.keywordInput" @click="clickItem(tab, tab.rightObject, item)" :class="{'active': item.active, 'selected': item.selected}"/>
                </van-list>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
import SearchInput from '@/components/speech/SearchInput'
export default {
  name: 'KeywordSelectDialog',
  components: {
    SearchInput
  },
  props: { },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id,
      statusBarHeight: state => state.global.statusBarHeight
    }),
    keyword: {
      get () {
        return this.$store.state.speech.inputKeyword
      },
      set (val) {
        this.$store.commit('speech/setInputKeyword', val)
      }
    }
  },
  data () {
    return {
      visible: false,
      metricsList: [],
      metricsLoding: false,
      metricsFinished: false,
      treeSelectHeight: 300,
      tabLlistData: [{
        text: '指标',
        searchValue: '',
        leftObject: {
          text: '指标',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: []
        },
        rightObject: {
          text: '分析术语',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: [],
          originData: [{
            'id': 'DIY_WORD_0',
            'keywordInput': '同比',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_1',
            'keywordInput': '环比',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_2',
            'keywordInput': '从大到小排序',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_3',
            'keywordInput': '从小到大排序',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_4',
            'keywordInput': '前3名',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_5',
            'keywordInput': '后5名',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_6',
            'keywordInput': '预测接下来3个月',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_7',
            'keywordInput': '平均值',
            'active': false,
            'selected': false
          }, {
            'id': 'DIY_WORD_8',
            'keywordInput': '中位数',
            'active': false,
            'selected': false
          }]
        }
      }, {
        text: '维度',
        searchValue: '',
        leftObject: {
          text: '维度',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          inputItems: [],
          children: []
        },
        rightObject: {
          text: '实体',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          dimensionNameIndex: 0, // 当前遍历查询到的维度位置
          activeItems: [],
          children: []
        }
      }, {
        text: '日期',
        searchValue: '',
        leftObject: {
          text: '日期',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: [],
          originData: [{
            'id': 'DATE-0',
            'keywordInput': '本月',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-1',
            'keywordInput': '本季度',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-2',
            'keywordInput': '今年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-3',
            'keywordInput': '上个月',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-4',
            'keywordInput': '上季度',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-5',
            'keywordInput': '去年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-6',
            'keywordInput': '前年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-7',
            'keywordInput': '上半年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-8',
            'keywordInput': '下半年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-9',
            'keywordInput': '去年上半年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-10',
            'keywordInput': '去年下半年',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-11',
            'keywordInput': '今天',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-12',
            'keywordInput': '昨天',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-13',
            'keywordInput': '前天',
            'active': false,
            'selected': false
          }]
        },
        rightObject: {
          text: '日期维度',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: [],
          originData: [{
            'id': 'DATE-DIMENSION-0',
            'keywordInput': '按月显示',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-DIMENSION-1',
            'keywordInput': '按季度显示',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-DIMENSION-2',
            'keywordInput': '按年显示',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-DIMENSION-3',
            'keywordInput': '按天显示',
            'active': false,
            'selected': false
          }, {
            'id': 'DATE-DIMENSION-4',
            'keywordInput': '按周显示',
            'active': false,
            'selected': false
          }]
        }
      }, {
        text: '模型',
        searchValue: '',
        leftObject: {
          text: '分析模型',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: []
        },
        rightObject: {
          text: '分析模型参数',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: [],
          originData: []
        }
      }, {
        text: '报告',
        searchValue: '',
        leftObject: {
          text: '分析报告',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: []
        },
        rightObject: {
          text: '分析报告参数',
          currentPage: -1,
          loading: false,
          finished: false,
          error: false,
          activeItems: [],
          children: [],
          originData: []
        }
      }],
      singleRadio: ['分析模型', '分析报告', '日期', '日期维度'],
      notClickItems: ['分析模型参数', '分析报告参数'],
      keywordCategory: {
        '指标': 'METRICS_NAME',
        '维度': 'DIMENSION_NAME',
        '实体': 'DIMENSION_VALUE',
        '分析模型': 'DIY_MODEL',
        '分析报告': 'RICH_REPORT'
      },
      menuActiveIndex: 0,
      keywordText: '',
      searchValue: ''
    }
  },
  methods: {
    showDialog () {
      this.visible = true
      window.history.pushState({ title: '关键词选择', url: '#' }, '关键词选择', '#')
      this.$nextTick(() => {
        this.treeSelectHeight = this.$el.offsetHeight - 350
      })
    },
    onLoad (tab, menu) {
      const keywordCategory = this.keywordCategory[menu.text]
      if (!keywordCategory) {
        Object.assign(menu.children, menu.originData)
        menu.loading = false
        menu.finished = true
        return
      }
      if (menu.text === '分析报告') {
        const _data = {
          page: menu.currentPage + 1,
          type: keywordCategory,
          dataRegionId: this.dataRegionId
        }
        this.$api.dashboard.getDashboardList(_data).then(res => {
          const content = []
          res.content.forEach(el => {
            let active = false
            let selected = false
            if (menu.activeItems.find(item => item.id === el.id)) { // 清空列表内容后重置选中样式
              active = true
              selected = true
            } else {
              active = false
              selected = false
            }
            content.push({
              'id': el['id'],
              'keywordInput': el['title'],
              'active': active,
              'selected': selected
            })
          })
          menu.children.push(...content)
          menu.currentPage = res.number
          menu.loading = false
          if (menu.currentPage >= res.totalPages - 1) { // 如果到最后一页，加载完成，但如果为实体，需要判断是否加载完所有维度实体
            menu.finished = true
          }
        }).catch(error => {
          menu.error = true
          this.$message.error(error.response.data.message)
        })
      } else {
        let relationKeyword
        if (menu.text === '实体') {
          const activeItem = tab['leftObject'].activeItems[menu['dimensionNameIndex']]
          if (!activeItem) {
            menu.loading = false
            menu.finished = true
            return
          }
          relationKeyword = activeItem.keywordInput
        }
        const _data = {
          page: menu.currentPage + 1,
          size: 15,
          keywordCategory: keywordCategory,
          relationKeyword: relationKeyword,
          dataRegionId: this.dataRegionId,
          searchKeyword: this.searchValue,
          synonym: false // 不显示同义词
        }
        this.$api.keyword.getKeywordList(_data).then(res => {
          res.content.forEach(el => {
            if (menu.activeItems.find(item => item.id === el.id)) { // 清空列表内容后重置选中样式
              el.active = true
              if (menu.text === '维度') {
                if (menu.inputItems.find(item => item.id === el.id)) {
                  el.selected = true // selected仅表示维度勾选了，active表示维度激活可以查找下属实体
                } else {
                  el.selected = false
                }
              } else {
                el.selected = true
              }
            } else {
              el.active = false
              el.selected = false
            }
          })
          menu.children.push(...res.content)
          menu.currentPage = res.number
          menu.loading = false
          if (menu.currentPage >= res.totalPages - 1) { // 如果到最后一页，加载完成，但如果为实体，需要判断是否加载完所有维度实体
            if (menu.text === '实体') {
              const dimensionNameActiveLength = tab['leftObject'].activeItems.length
              if (menu['dimensionNameIndex'] === dimensionNameActiveLength - 1) { // 已经加载完最后一个维度
                menu.finished = true
              } else {
                menu['dimensionNameIndex']++
              }
              menu['currentPage'] = -1
            } else {
              menu.finished = true
            }
          }
        }).catch(error => {
          menu.error = true
          this.$message.error(error.response.data.message)
        })
      }
    },
    clickItem (tab, menu, item) {
      if (this.notClickItems.includes(menu.text)) { // 不能选中
        return
      }
      const index = menu.activeItems.findIndex(el => el.id === item.id)
      if (index === -1) {
        if (this.singleRadio.includes(menu.text)) { // 单选，去掉之前选中的
          menu.activeItems.forEach(i => {
            const activeItem = menu['children'].find(e => e.id === i.id)
            activeItem.active = false
            activeItem.selected = false
          })
          menu.activeItems.splice(0)
        }
        menu.activeItems.push(item)
        item.active = true
        if (menu.text === '维度') {
          tab['rightObject']['children'].splice(0) // 按第一个维度名开始查找重新添加，清空内容避免重复
          tab['rightObject']['dimensionNameIndex'] = 0
          tab['rightObject']['currentPage'] = -1
          if (!tab['rightObject']['finished']) { // finished一直为false不会自动加载数据
            this.onLoad(tab, tab['rightObject'])
          } else {
            tab['rightObject']['finished'] = false // finished从true变为false会自动加载
          }
          item.selected = false
        } else {
          item.selected = true
          if (menu.text === '分析模型') {
            this.getDiyModelParams(tab, menu)
          } else if (menu.text === '分析报告') {
            this.getRichReportParams(tab, item)
          }
        }
      } else {
        if (menu.text === '维度') {
          const inputIndex = menu.inputItems.findIndex(el => el.id === item.id)
          if (inputIndex === -1) { // activeItems内有，inputIds里没有时，为点第第二下，填入input
            menu.inputItems.push(item)
            item.selected = true
          } else { // activeItems内有，inputIds里也有时，为点第三下，去除input和activeItems
            menu.activeItems.splice(index, 1)
            menu.inputItems.splice(inputIndex, 1)
            item.active = false
            item.selected = false
            tab['rightObject']['children'].splice(0) // 按第一个维度名开始查找重新添加，清空内容避免重复
            const activeItems = tab['rightObject']['activeItems'].filter(el => el.relationKeyword !== item.keywordInput) // activeItems找出取消选中的维度选择出来的实体，去掉选中
            tab['rightObject']['activeItems'].splice(0)
            tab['rightObject']['activeItems'].push(...activeItems)
            tab['rightObject']['dimensionNameIndex'] = 0
            tab['rightObject']['currentPage'] = -1
            if (!tab['rightObject']['finished']) { // 如果少了维度，实体需要重新加载 finished一直为false不会自动加载数据
              this.onLoad(tab, tab['rightObject'])
            } else {
              tab['rightObject']['finished'] = false // finished从true变为false会自动加载
            }
            if (menu.activeItems.length === 0) { // 没有选择维度，不显示实体
              tab['rightObject']['finished'] = true
              tab['rightObject']['activeItems'].splice(0) // 清空已选择项
            }
          }
        } else {
          menu.activeItems.splice(index, 1)
          item.active = false
          item.selected = false
          if (this.singleRadio.includes(menu.text)) {
            if (this.notClickItems.includes(menu.text)) {
              tab['rightObject']['children'].splice(0) // 清空内容
            }
          }
        }
      }
      this.changeInputText()
    },
    getDiyModelParams (tab, menu) {
      tab['rightObject']['children'].splice(0) // 清空内容避免重复
      tab['rightObject']['originData'].splice(0) // 清空内容避免重复
      menu.activeItems.forEach(item => {
        item['diyModel']['params'].forEach(el => { // 从模型数据中获取参数信息
          tab['rightObject']['children'].push({
            'id': el['name'],
            'keywordInput': el['ref'],
            'active': false,
            'selected': false
          })
        })
      })
      Object.assign(tab['rightObject']['originData'], tab['rightObject']['children']) // 用以后面的筛选搜索
    },
    getRichReportParams (tab, item) {
      tab['rightObject']['children'].splice(0) // 清空内容避免重复
      tab['rightObject']['originData'].splice(0) // 清空内容避免重复
      this.$api.speech.getKeywords(item.id, 'REPORT_VIEW').then(res => {
        res.forEach(el => {
          tab['rightObject']['children'].push({
            'id': el['id'],
            'keywordInput': el['keywordInput'],
            'active': false,
            'selected': false
          })
        })
      })
      Object.assign(tab['rightObject']['originData'], tab['rightObject']['children'])
    },
    changeInputText () {
      this.keywordText = ''
      this.tabLlistData.forEach(menu => {
        const leftChild = menu['leftObject']
        const rightChild = menu['rightObject']
        this.keywordText += this.changeChildInputText(leftChild)
        this.keywordText += this.changeChildInputText(rightChild)
      })
      this.keyword = this.keywordText
    },
    changeChildInputText (menu) {
      let keywordText = ''
      if (menu.text === '维度') {
        menu.inputItems.forEach(el => { keywordText += el.keywordInput })
      } else {
        menu.activeItems.forEach(el => { keywordText += el.keywordInput })
      }
      return keywordText
    },
    resetItemList () {
      this.tabLlistData.forEach(menu => {
        const leftChild = menu['leftObject']
        const rightChild = menu['rightObject']
        this.resetItemChild(leftChild)
        this.resetItemChild(rightChild)
      })
    },
    resetItemChild (menu) {
      menu['currentPage'] = -1
      menu['finished'] = false
      menu['activeItems'].splice(0)
      menu['children'].splice(0)
      if (menu.text === '实体') {
        menu['disabled'] = false
        menu['dimensionNameIndex'] = 0
      } else if (menu.text === '维度') {
        menu['inputItems'].splice(0)
      }
    },
    onSearch () {
      const tab = this.tabLlistData[this.menuActiveIndex]
      const leftChild = tab['leftObject']
      const rightChild = tab['rightObject']
      this.onSearchChild(tab, leftChild)
      this.onSearchChild(tab, rightChild)
      tab['searchValue'] = this.searchValue
    },
    onSearchChild (tab, menu) {
      menu['children'].splice(0)
      if (!this.keywordCategory[menu.text]) {
        console.log('keywordCategory', menu.text)
        console.log(menu['originData'])
        const array = []
        menu['originData'].forEach(el => {
          if (el['keywordInput'].search(this.searchValue) !== -1) {
            array.push(el)
          }
        })
        menu['children'].push(...array)
      } else {
        menu['currentPage'] = -1
        if (!menu['finished']) {
          this.onLoad(tab, menu) // finished一直为false不会自动加载数据
        } else {
          menu['finished'] = false // finished从true变为false会自动加载
        }
      }
    },
    onRefresh () {
      this.searchValue = ''
      this.onSearch()
    }
  },
  mounted() {},
  watch: {
    dataRegionId () {
      this.resetItemList()
    },
    menuActiveIndex () {
      this.searchValue = this.tabLlistData[this.menuActiveIndex]['searchValue']
    }
  }
}
</script>
<style lang="less">
.Keyword-select-dialog {
  z-index: 10;
  .wrapper {
    margin: 0 15px;
    .search-input {
      padding: 0;
      .van-search {
        background-color: transparent;
        padding-left: 0;
        .van-search__content {
          border-radius: 5px 0 0 5px;
          background-color: #ffffff;
        }
        .van-search__action {
          background-color: #ffffff;
          height: 20px;
          border-radius: 0 5px 5px 0;
          padding: 7px 0;
          div {
            line-height: 20px;
            padding: 0 18px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #319DE2;
            border-left: 1px solid #D4D4D7;
          }
        }
      }
      .scroller-wrapper {
        // height: calc(100vh - 100px);
        position: relative;
      }
    }
    .dialog-box {
      border-radius: 5px;
      margin-top: 50px;
      overflow: hidden;
      background-color: #ffffff;
      padding: 5px;
      .dialog-title {
        position: relative;
        top: 44px;
        z-index: 1;
      }
      .dialog-content {
        .van-tabs__nav--card {
          margin: 0;
          padding: 0;
        }
        .van-tabs__wrap {
          position: relative;
          top: -44px;
        }
        .tab-content {
          display: flex;
          .tab-content-left {
            border-right: 1px dashed #c4c4c4;
          }
          .tab-content-left, .tab-content-right {
            flex: 1;
            overflow: hidden;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            ::-webkit-scrollbar { width: 0 !important }
          }
          .van-list {
            height: calc(100% - 18px);
            overflow: auto;
            .van-list__finished-text, .van-loading__text, .van-list__error-text {
              font-size: 12px;
            }
            .active {
              position: relative;
              color: red;
            }
            .selected::before {
              content: '✓';
              position: absolute;
              box-sizing: border-box;
              pointer-events: none;
              right: 5px;
            }
            .van-cell__title {
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
