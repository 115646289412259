<template>
  <div class="speech-recomment-word">
    <div>
      <van-cell class="speech-recomment-word-header">
        <div slot="title" class="title-box">
          <div class="title">搜索发现</div>
        </div>
        <div slot="extra" class="confirm-btn" @click="onConfirm">
          确定
        </div>
      </van-cell>
      <div class="recomment-list" >
        <RecommentWord :recommentWordParams="recommentWordParams" @getActiveWords="getActiveWords" @openMore="showMore" type="speechsearch"/>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import RecommentWord from '@/components/speech/RecommentWord'

export default {
  name: 'SpeechRecommentWord',
  components: {
    RecommentWord
  },
  props: {
    keyword: {
      type: String,
      default: ''
    },
    keywordMatchedList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dataList: [],
      loading: false,
      activeWords: []
    }
  },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id
    }),
    recommentWordParams () {
      const array = []
      this.keywordMatchedList.forEach(item => {
        array.push({
          wordName: item.keywordInput,
          wordType: item.keywordCategoryDisplayName,
          wordRelation: item.relationKeyword || ''
        })
      })
      const params = {
        words: array,
        limit: 10,
        dataRegionId: this.dataRegionId
      }
      return params
    }
  },
  methods: {
    showMore () {
      this.$parent.$parent.$refs['RecommentChooseDialog'].showDialog()
    },
    getActiveWords (activeWords) {
      this.activeWords = activeWords
      this.$emit('recommentActiveWord', activeWords)
    },
    onConfirm () {
      let _keyword = ''
      if (this.activeWords[0]?.id === 'reference') {
        _keyword += this.keyword + ' '
        this.activeWords.splice(0, 1)
      }
      _keyword += this.activeWords.map(item => item.nodeName).join(' ')
      this.$emit('FilterParseKeyword', _keyword)
      this.$store.dispatch('speech/setInputKeyword', _keyword)
    }
  },
  mounted() {
  }
}
</script>
<style lang="less">
.speech-recomment-word {
  // padding: 5px 5px 10px;
  // border: 1px solid #E3E5E8;
  border-radius: 5px;
  // margin: 10px 12px;
  .speech-recomment-word-header {
    padding: 10px 12px;
    // padding: 0;
    .title-box {
      .title {
        display: inline-block;
        font-size: 15px;
        // font-weight: bold;
        // margin-left: 10px;
      }
    }
    .confirm-btn {
      font-weight: bold;
      padding-right: 10px;
    }
  }

  .recomment-list {
    // margin: 0 12px 10px 12px;
    // border: 1px solid #E3E5E8;
  }
}
</style>
