<template>
  <div class="android-share-tips">
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
  </div>
</template>
<script>
export default {
  name: 'AndroidShareTips',
  data () {
    return {
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: '朋友圈', icon: 'wechat-moments' },
          { name: '邮件', icon: require('@/assets/img/icon/email.png') },
          { name: 'QQ', icon: 'qq' }
        ]
      ]
    }
  },
  computed: {},
  methods: {
    show () {
      // console.log(this.showShare)
      this.showShare = true
    },
    onSelect (option) {
      const name = option.name
      if (name === '微信') {
        console.log('refSearchSpeech', this.$parent.$refs['refSearchSpeech'][0].$refs['speechView'])
        const refSpeechView = this.$parent.$refs['refSearchSpeech'][0].$refs['speechView']
        const thumb = refSpeechView.smartChartList[refSpeechView.activeTab].boxThumb
        if (thumb) {
          window['XbiApp']['sendToWx'](thumb, refSpeechView.keywordMatched.keyword)
        } else {
          this.$toast('分享出错，请稍后再试')
        }
        this.showShare = false
        // const refSpeechView = this.$parent.$refs['speechView']
        // const thumb = refSpeechView.smartChartList[refSpeechView.active].boxThumb
        // if (thumb) {
        //   window['XbiApp']['sendToWx'](thumb, refSpeechView.keywordMatched.keyword)
        // } else {
        //   this.$toast('分享出错，请稍后再试')
        // }
        // this.showShare = false
      }
    }
  },
  watch: {
    showShare (newVal) {
      if (newVal) {
        window.history.pushState({ title: '分享', url: '#' }, '分享', '#')
      } else if (history.state.title === '分享') {
        history.back()// 回退分享页面显示的历史
        history.back()// 回退操作选项的历史
      } else if (history.state.title === '图表选项') {
        history.back()// 回退操作选项的历史
      }
    }
  }
}
</script>
<style lang="less">
.android-share-tips {
  .van-share-sheet__options {
    .van-share-sheet__option {
      flex: 1;
    }
  }
}
</style>
