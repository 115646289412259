<template>
  <div class="chart-thumb" >
    <!-- <div class="chart-thumb" :style="{ width: `${width}px`, height: `${height}px` }"> -->
    <img v-if="chartType === 'Area'" src="@/assets/img/charts/area.svg"/>
    <img v-if="chartType === 'Bar'" src="@/assets/img/charts/bar.svg"/>
    <img v-if="chartType === 'Bubble'" src="@/assets/img/charts/bubble.svg"/>
    <img v-if="chartType === 'Column'" src="@/assets/img/charts/column.svg"/>
    <img v-if="chartType === 'Ring'" src="@/assets/img/charts/donut.svg"/>
    <img v-if="chartType === 'GroupedBar'" src="@/assets/img/charts/grouped_bar.svg"/>
    <img v-if="chartType === 'GroupedColumn'" src="@/assets/img/charts/grouped_column.svg"/>
    <img v-if="chartType === 'Table'" src="@/assets/img/charts/table.svg"/>
    <img v-if="chartType === 'Tree'" src="@/assets/img/charts/heatmap.svg"/>
    <img v-if="chartType === 'KeyValue'" src="@/assets/img/charts/key_value.svg"/>
    <img v-if="chartType === 'Histogram'" src="@/assets/img/charts/histogram.svg"/>
    <img v-if="chartType === 'Line'" src="@/assets/img/charts/line.svg"/>
    <img v-if="chartType === 'PercentStackedArea'" src="@/assets/img/charts/percent_stacked_area.svg"/>
    <img v-if="chartType === 'PercentStackedBar'" src="@/assets/img/charts/percent_stacked_bar.svg"/>
    <img v-if="chartType === 'PercentStackedColumn'" src="@/assets/img/charts/percent_stacked_column.svg"/>
    <img v-if="chartType === 'Pie'" src="@/assets/img/charts/pie.svg"/>
    <img v-if="chartType === 'Radar'" src="@/assets/img/charts/radar.svg"/>
    <img v-if="chartType === 'ScatterPlot'" src="@/assets/img/charts/scatter_plot.svg"/>
    <img v-if="chartType === 'StackedArea'" src="@/assets/img/charts/stacked_area.svg"/>
    <img v-if="chartType === 'StackedBar'" src="@/assets/img/charts/stacked_bar.svg"/>
    <img v-if="chartType === 'StackedColumn'" src="@/assets/img/charts/stacked_column.svg"/>
    <img v-if="chartType === 'FacetArea'" src="@/assets/img/charts/area_multi.svg"/>
    <img v-if="chartType === 'BarTable'" src="@/assets/img/charts/bar_multi.svg"/>
    <img v-if="chartType === 'FacetLine'" src="@/assets/img/charts/line_multi.svg"/>
    <img v-if="chartType === 'FacetColumn'" src="@/assets/img/charts/column_multi.svg"/>
  </div>
</template>
<script>

export default {
  name: 'ChartThumb',
  components: {
  },
  props: {
    // width: {
    //   type: Number,
    //   default: 60
    // },
    // height: {
    //   type: Number,
    //   default: 60
    // },
    chartType: {
      type: String,
      default: 'Column'
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="less">
.chart-thumb {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
