<template>
  <van-dialog v-model="visible" show-cancel-button class="recomments-choose-dialog" @confirm="onSubmit" :close-on-click-overlay="true">
    <div class="filter-content" :style="{height: contentHeight + 'px'}">
      <scroller class="scroller" snapping>
        <van-collapse v-model="activeNames">
          <van-collapse-item name="keyword_parse">
            <strong slot="title">解析关键词</strong>
            <template v-for="(item, index) in keywordList">
              <div
                class="keyword_parse"
                :key="'keyword' + index"
                v-if="!item.implicit || !ignoreImplicit"
                @click="onKeywordClick(item)"
                :class="{ keyword_parse: true, keyword_parse_active: item.active }">
                {{ item['matchedValue'] || item['keywordInput'] }}
              </div>
            </template>
          </van-collapse-item>
          <van-collapse-item name="recomment">
            <strong slot="title">相关搜索</strong>
            <RecommentWord type="dialog" :defaultActiveWords="getDefaultActiveWords" :recommentWordParams="recommentWordParams" @getActiveWords="getRecommentActiveWords"/>
          </van-collapse-item>
          <van-collapse-item
            v-for="filter in filters"
            :key="filter.key"
            :name="filter.key">
            <strong slot="title">{{ filter.label }}</strong>
            <van-checkbox-group v-model="filter['value']" direction="horizontal">
              <van-cell
                v-for="item in filter['options']"
                :key="item"
                clickable>
                <van-checkbox :name="item" shape="square">{{ item }}</van-checkbox>
              </van-cell>
            </van-checkbox-group>
          </van-collapse-item>
        </van-collapse>
      </scroller>
    </div>
  </van-dialog>
</template>
<script>
import ReportChartData from '@/utils/reportData'
import RecommentWord from '@/components/speech/RecommentWord'

export default {
  name: 'RecommentsChooseDialog',
  props: {
    recommentActiveWord: {
      type: Array,
      default() {
        return []
      }
    },
    dataRegionId: {
      type: String,
      default: ''
    },
    keywordMatched: {
      type: Object,
      default() {
        return {
          keywordList: []
        }
      }
    },
    reportData: {
      type: Object,
      default() {
        return {
          suggest: {
            dimensions: [],
            metrics: []
          }
        }
      }
    },
    ignoreImplicit: {
      type: Boolean,
      default: true
    }
  },
  components: {
    RecommentWord
  },
  data() {
    return {
      visible: false,
      activeNames: ['keyword_parse', 'recomment'],
      reportChartData: {},
      filters: [],
      contentHeight: 300,
      parseActiveWords: [],
      recommentActiveWords: [],
      keywordList: []
    }
  },
  computed: {
    recommentWordParams () {
      const array = []
      this.keywordList.forEach(item => {
        array.push({
          wordName: item.keywordInput,
          wordType: item.keywordCategoryDisplayName,
          wordRelation: item.relationKeyword || ''
        })
      })
      const params = {
        words: array,
        limit: 10,
        dataRegionId: this.dataRegionId
      }
      return params
    },
    getDefaultActiveWords () {
      const defaultActiveWords = [...this.recommentActiveWord]
      const index = this.recommentActiveWord.findIndex(item => item.id === 'reference')
      if (index !== -1) {
        defaultActiveWords.splice(index, 1)
      }
      return defaultActiveWords
    }
  },
  methods: {
    showDialog () {
      this.visible = true
      this.$nextTick(() => {
        this.contentHeight = this.$parent.$el.offsetHeight - 200
      })
    },
    onSubmit() {
      const _keywords = []
      this.parseActiveWords.forEach(item => {
        _keywords.push(item['keywordInput'])
      })
      this.recommentActiveWords.forEach(filter => {
        _keywords.push(filter['nodeName'])
      })
      this.filters.forEach(filter => {
        _keywords.push(...filter['value'])
      })
      this.$emit('submit', _keywords.join(' '))
      this.$store.dispatch('speech/setInputKeyword', _keywords.join(' '))
    },
    parseReportData() {
      this.filters.splice(0)
      this.reportChartData = new ReportChartData(this.reportData)
      const _metricsOptions = this.reportData['suggest'] ? this.reportData['suggest']['metrics'] : this.reportChartData['metrics']
      const _metrics = {
        key: 'metrics',
        label: '数据集关联指标',
        collapsed: true,
        options: [..._metricsOptions],
        value: []
        // value: [...this.reportChartData['metrics']]
      }
      const _dimensionsOptions = this.reportData['suggest'] ? [...this.reportData['suggest']['dimensions']] : this.reportChartData['dimensions']
      // add 时间维度
      const _indexDatetime = _dimensionsOptions.indexOf('日期')
      if (_indexDatetime > -1) {
        _dimensionsOptions.splice(_indexDatetime, 1, ...['日期', '月份', '年月', '年份'])
      }
      const _dimensions = {
        key: 'dimensions',
        label: '数据集关联维度',
        collapsed: true,
        options: [..._dimensionsOptions],
        value: []
        // value: [...this.reportChartData['dimensions']]
      }
      this.filters.push(_metrics, _dimensions)
    },
    onKeywordClick (item) {
      const index = this.parseActiveWords.findIndex(el => el.id === item.id)
      if (index === -1) {
        item.active = true
        this.parseActiveWords.push(item)
      } else {
        item.active = false
        this.parseActiveWords.splice(index, 1)
      }
      this.$forceUpdate()
    },
    getRecommentActiveWords (activeWords) {
      this.recommentActiveWords = activeWords
    }
  },
  mounted() {
    this.parseReportData()
  },
  watch: {
    recommentActiveWord: {
      handler () {
        const { keywordList } = this.keywordMatched
        const index = this.recommentActiveWord.findIndex(item => item.id === 'reference')
        if (index !== -1) {
          keywordList.forEach(item => { item.active = true })
        } else {
          keywordList.forEach(item => { item.active = false })
        }
        this.keywordList = keywordList
        this.parseActiveWords = [...keywordList]
      },
      deep: true,
      immediate: true
    },
    keywordMatched: {
      handler () {
        const { keywordList } = this.keywordMatched
        const index = this.recommentActiveWord.findIndex(item => item.id === 'reference')
        if (index !== -1) {
          keywordList.forEach(item => { item.active = true })
        } else {
          keywordList.forEach(item => { item.active = false })
        }
        this.keywordList = keywordList
        this.parseActiveWords = [...keywordList]
      },
      deep: true,
      immediate: true
    },
    reportData() {
      this.parseReportData()
    },
    visible (newVal) {
      if (newVal) {
        window.history.pushState({ title: '热词推荐选择', url: '#' }, '热词推荐选择', '#')
      } else if (history.state.title === '热词推荐选择') {
        history.back()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.recomments-choose-dialog {
  z-index: 10;
  .filter-content {
    position: relative;
    height: calc(100% - 46px);
    .keyword_parse {
      display: inline-block;
      font-size: 14px;
      // border: 1px solid #c8c9ca;
      padding: 2px 4px;
      margin: 4px 6px;
      border-radius: 5px
    }
    .keyword_parse_active {
      background-color: #5b86e5;
      color: #ffffff;
      // font-weight: bold;
    }
  }
}
</style>
